<template>
  <v-row>
    <v-col cols="2">
      <v-icon size="30px" :class="`${iconColor}--text`"> {{ icon }} </v-icon>
    </v-col>
    <v-col cols="6" :class="`${textColor}--text`">
      <h4>{{ label }}</h4>
    </v-col>
    <v-col cols="4" :class="`${textColor}--text text-end`">
      <h3>{{ value }}</h3>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "table-row-item",
  components: {},
  props: {
    backgroundColor: {
      type: String,
      required: true,
    },
    textColor: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    iconColor: {
      type: String,
      required: true,
    },
  },
};
</script>
