<template>
  <v-app class="custom-scrollbar">
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
body {
  overflow-y: hidden;
}

.custom-scrollbar {
  overflow-y: auto;
}

.centered-input input {
  text-align: center;
}
.v-card{
  border: none !important; /* Elimina el borde */
  border-radius: 0 !important; /* Elimina el radio de borde si lo hubiera */
  box-shadow: none !important; /* Elimina la sombra si la hay */
}

</style>
