<template>
  <component
    class="dynamic-icon"
    :is="dynamicIcon"
    @click="$emit('icon-click')"
    :style="cssVars"
  />
</template>

<script>
export default {
  name: "DynamicIcon",
  props: {
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
    },
  },
  computed: {
    dynamicIcon() {
      return () => import(`../assets/${this.icon}.svg`);
    },
    cssVars() {
      return {
        "--icon-color": this.color,
      };
    },
  },
};
</script>

<style scoped>
.dynamic-icon {
  fill: var(--icon-color, currentColor);
}
</style>