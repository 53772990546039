import store from "@/store";

export async function login(data) {
  try {
    await store.dispatch("login", data);
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
}

export async function logout() {
  try {
    await store.dispatch("logout");
  } catch (error) {
    console.error("Logout error:", error);
    throw error;
  }
}

export async function user_info() {
  const url = "/api/users/info";
  const response = await fetch(url, {
    credentials: "same-origin",
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error get user info");
  }
}

export async function recover_password(data) {
  const url = "/api/accounts/recover";
  const response = await fetch(url, {
    credentials: "same-origin",
    method: "POST",
    body: JSON.stringify(data),
  });
  if (response.status == 200) {
    return response.json();
  } else {
    const errorData = await response.json();
    throw new Error(errorData.message);
  }
}

export async function reset_password(data) {
  const url = "/api/accounts/reset";
  const response = await fetch(url, {
    credentials: "same-origin",
    method: "POST",
    body: JSON.stringify(data),
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error changing password");
  }
}

export async function check_token_expired(token) {
  const url = "/api/accounts/check-token-expired";
  const response = await fetch(url, {
    credentials: "same-origin",
    method: "POST",
    body: JSON.stringify(token),
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error changing password");
  }
}
