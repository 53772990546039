
export async function getUrls() {
    const url = "/api/urls/url";
    const response = await fetch(url, {
      method: "GET",
    });
    if (response.status == 200) {
      return response.json();
    } else {
      throw new Error("Error getting urls info");
    }
  }

  export async function getUrl(id_url) {
    const url = "/api/urls/url/" + id_url;
    const response = await fetch(url, {
      method: "GET",
    });
    if (response.status == 200) {
      return response.json();
    } else {
      throw new Error("The Url entered does not exist");
    }
  }

  export async function update_url(idUrl, data) {
    const url = "/api/urls/url/" + idUrl;
    const response = await fetch(url, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    if (response.status == 200) {
      return response.json();
    } else {
      throw new Error("Error actualizando url");
    }
  }

  export async function create_url(data) {
    const url = "/api/urls/url";
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
    });
    if (response.status == 200) {
      return response.json();
    } else {
      throw new Error("Error creando url");
    }
  }

  export async function deleteUrl(id_url) {
    const url = "/api/urls/url/" + id_url;
    const response = await fetch(url, {
      method: "DELETE",
    });
    if (response.status == 200) {
      return response.json();
    } else {
      throw new Error("Error delete url");
    }
  }
