<template>
  <v-card class="custom-card" cols="12" xl="12" lg="12" md="12" sm="12" :loading="loading">
    <v-card-title
      class="justify-center"
      cols="12"
      xl="12"
      lg="12"
      md="12"
      sm="12"
    >
      <span class="card-value ml-5"> {{ $t(`pie_chart.` + title.toLowerCase()).toUpperCase() }} </span>
    </v-card-title>
    <v-card-text>
      <Pie v-if="!error" :data="chartData" :options="charOptions" />
      <span v-if="error" class="ml-5"> {{ error }} </span>
    </v-card-text>
  </v-card>
</template>

<script>
// @ is an alias to /src
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "vue-chartjs";

ChartJS.register(ArcElement, Tooltip, Legend);

export default {
  name: "chart-pie-item",
  components: {
    Pie,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    error: {
      type: String,
      default: null,
    },
    // arrayElementos: {
    //   type: Array,
    //   required: true,
    // },
    chartData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      charOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
};
</script>
