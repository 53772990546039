import Vue from "vue";
import VueRouter from "vue-router";
import { lazyLoad } from "@/utils.js";

import Login from "@/views/Login.vue";
import Recover from "@/views/Login/Recover.vue";
import Reset from "@/views/Login/Reset.vue";
import App from "@/views/App.vue";
import Escritorio from "@/views/Escritorio.vue";
import Clientes from "@/views/Clientes/Clientes.vue";
import Software from "@/views/Software/LaborTypes.vue";
import Administracion from "@/views/Administracion/Administracion.vue";
import Flota from "@/views/Flota/Flota.vue";
import UrlMenu from "@/views/Url/UrlMenu.vue";
import Distribuidores from "@/views/Distribuidores/Distribuidores.vue";

Vue.use(VueRouter);

// const routeImages = "../assets/"
export const pages = [
  {
    path: "/escritorio",
    name: "Escritorio",
    icon: "mdi-monitor",
    component: Escritorio,
    meta: {
      title: "Escritorio",
      button: "Escritorio",
      section: "Escritorio",
      module: "Escritorio",
    },
  },
  {
    path: "/flota",
    name: "Flota",
    icon: "mdi-tractor",
    component: Flota,
    meta: {
      title: "Flota",
      section: "Flota",
    },
    children: [
      {
        path: "maquina",
        name: "Máquinas",
        icon: "mdi-tractor-variant",
        component: () => lazyLoad(import("@/views/Flota/Machines.vue")),
        meta: {
          title: "Máquinas",
          button: "Máquinas",
          section: "Flota",
          module: "Máquinas",
        },
      },
      {
        path: "maquina/nueva",
        name: "Nueva máquina",
        hidden: true,
        component: () => lazyLoad(import("@/views/Flota/NewMachine.vue")),
        meta: {
          title: "Nueva Máquina",
          button: "Máquina",
          section: "Flota",
          module: "Máquinas",
        },
      },
      {
        path: "maquina/editar/:id",
        name: "Editar máquina",
        hidden: true,
        component: () => lazyLoad(import("@/views/Flota/NewMachine.vue")),
        meta: {
          title: "Editar Máquina",
          button: "Máquina",
          section: "Flota",
          module: "Máquinas",
        },
      },
      {
        path: "maquina/detalle/:id",
        name: "Detalle máquina",
        hidden: true,
        component: () => lazyLoad(import("@/views/Flota/MachineDetail.vue")),
        meta: {
          title: "Detalle Máquina",
          button: "Máquina",
          section: "Flota",
          module: "Máquinas",
        },
      },
      {
        path: "maquina/registro/:id",
        name: "Registro máquina",
        hidden: true,
        component: () => lazyLoad(import("@/views/Flota/MachineRegister.vue")),
        meta: {
          title: "Registro Máquina",
          button: "Máquina",
          section: "Flota",
          module: "Máquinas",
          permissions: {
            module_name: "module_machines",
            required_permission: "WRITE",
          },
        },
      },
      {
        path: "maquina/activacion/:id",
        name: "Activación máquina",
        hidden: true,
        component: () =>
          lazyLoad(import("@/views/Flota/MachineActivation.vue")),
        meta: {
          title: "Activación Máquina",
          button: "Máquina",
          section: "Flota",
          module: "Máquinas",
          permissions: {
            module_name: "module_machines",
            required_permission: "WRITE",
          },
        },
      },
      {
        path: "maquina/etiquetas/:id",
        name: "Editar Etiquetas",
        hidden: true,
        component: () =>
          lazyLoad(import("@/views/Flota/MachineEditLabels.vue")),
        meta: {
          title: "Editar Etiquetas",
          section: "Flota",
          module: "Máquinas",
        }
      },
      {
        path: "maquina/garantia/:id",
        name: "Solicitud garantía máquina",
        hidden: true,
        component: () =>
          lazyLoad(import("@/views/Flota/MachineWarranty.vue")),
        meta: {
          title: "Garantía Máquina",
          button: "Máquina",
          section: "Flota",
          module: "Máquinas",
          permissions: {
            module_name: "module_machines",
            required_permission: "WRITE",
          },
        },
      },
      {
        path: "alarma",
        name: "Alarmas",
        icon: "mdi-alert-outline",
        component: () => lazyLoad(import("@/views/Flota/Alarms.vue")),
        meta: {
          title: "Alarmas",
          button: "Alarmas",
          section: "Flota",
          module: "Alarmas",
        },
      },
      {
        path: "alarma/detalle/:id",
        name: "Detalle alarma",
        hidden: true,
        component: () => lazyLoad(import("@/views/Flota/AlarmDetail.vue")),
        meta: {
          title: "Detalle Alarma",
          button: "Alarma",
          section: "Flota",
          module: "Alarmas",
        },
      },
      {
        path: "alarma/reset/mantenimiento/:id",
        name: "Reseteo de mantenimiento",
        hidden: true,
        component: () => lazyLoad(import("@/views/Flota/ResetMaintenance.vue")),
        meta: {
          title: "Reseteo de mantenimiento",
          button: "Alarma",
          section: "Flota",
          module: "Alarmas",
        },
      },
      {
        path: "alarma/reset/alarma/:id",
        name: "Reseteo de Alarma",
        hidden: true,
        component: () => lazyLoad(import("@/views/Flota/ResetAlarm.vue")),
        meta: {
          title: "Reseteo de Alarma",
          button: "Alarma",
          section: "Flota",
          module: "Alarmas",
        },
      },
    ],
  },
  {
    path: "/distribuidor",
    name: "DistribuidoresMenu",
    icon: "mdi-account-group-outline",
    component: Distribuidores,
    meta: {
      title: "Distribuidores",
      section: "DistribuidoresMenu",
      module: "DistribuidoresMenu",
      permissions: {
        module_name: "section_distributors",
        required_permission: "READ",
      },
    },
  },
  {
    path: "/distribuidor/detalle/:id",
    name: "Detalles distribuidor",
    hidden: true,
    component: () => lazyLoad(import("@/views/Distribuidores/DistributorDetail.vue")),
    meta: {
      title: "Detalle Distribuidor",
      section: "DistribuidoresMenu",
      module: "DistribuidoresMenu",
      permissions: {
        module_name: "section_distributors",
        required_permission: "READ",
      },
    },
  },
  {
    path: "/cliente",
    name: "Clientes",
    icon: "mdi-account-outline",
    component: Clientes,
    meta: {
      title: "Clientes",
      section: "Clientes",
      module: "Clientes",
    },
  },
  {
    path: "/cliente/detalle/:id",
    name: "Detalles cliente",
    hidden: true,
    component: () => lazyLoad(import("@/views/Clientes/ClientDetail.vue")),
    meta: {
      title: "Clientes",
      section: "Clientes",
      module: "Clientes",
    },
  },
  {
    path: "/cliente/nuevo",
    name: "Nuevo cliente",
    hidden: true,
    component: () => lazyLoad(import("@/views/Clientes/ClientNew.vue")),
    meta: {
      title: "Nuevo cliente",
      section: "Clientes",
      module: "Clientes",
      permissions: {
        module_name: "section_clients",
        required_permission: "ADD",
      },
    },
    props: {
      modo: 'nuevo',
      titleLabel: 'Nuevo'
    },
  },
  {
    path: "/cliente/editar/:id",
    name: "Editar cliente",
    hidden: true,
    component: () => lazyLoad(import("@/views/Clientes/ClientNew.vue")),
    meta: {
      title: "Editar cliente",
      section: "Clientes",
      module: "Clientes",
      permissions: {
        module_name: "section_clients",
        required_permission: "WRITE",
      },
    },
    props: {
      modo: 'editar',
      titleLabel: 'Editar'
    },
  },
  {
    path: "/clientes/importacion",
    name: "Cargar clientes",
    hidden: true,
    component: () => lazyLoad(import("@/views/Clientes/ClientImport.vue")),
    meta: {
      title: "Cargar Clientes",
      section: "Clientes",
      module: "Clientes",
      permissions: {
        module_name: "section_clients",
        required_permission: "ADD",
      },
    },
  },
  {
    path: "/software",
    name: "Software",
    icon: "mdi-download-outline",
    component: Software,
    meta: {
      title: "Software",
      section: "Software",
      module: "Software",
    },
    // children: [
    //   {
    //     path: "tiposLabor",
    //     name: "Tipos Labor",
    //     component: () =>
    //       lazyLoad(import("@/views/Software/LaborTypes.vue")),
    //     meta: {
    //       title: "Usuarios",
    //       button: "Usuarios",
    //       section: "Administración",
    //     },
    //   },
    // ],
  },
  {
    path: "/software/labor_type/:id",
    name: "Series Types",
    hidden: true,
    component: () => lazyLoad(import("@/views/Software/SeriesTypes.vue")),
    meta: {
      title: "Software",
      section: "Software",
      module: "Software",
    },
  },
  {
    path: "/software/ecus/:laborTypeId/:seriesTypeId",
    name: "SoftwareEcus",
    hidden: true,
    component: () => lazyLoad(import("@/views/Software/SoftwareEcus.vue")),
    meta: {
      title: "Software ECUs",
      section: "Software",
      module: "Software",
    },
  },
  {
    path: "/software/details/:laborTypeId/:seriesTypeId/:ecuTypeId",
    name: "SoftwareDetails",
    hidden: true,
    component: () => lazyLoad(import("@/views/Software/SoftwareDetails.vue")),
    meta: {
      title: "Software Details",
      section: "Software",
      module: "Software",
    },
  },
  {
    path: "/software/serie_type",
    name: "Series Types Search",
    hidden: true,
    component: () => lazyLoad(import("@/views/Software/SeriesTypesSearch.vue")),
    meta: {
      title: "Software",
      section: "Software",
      module: "Software",
    },
  },
  {
    path: "/url-menu",
    name: "UrlsMenu",
    icon: "mdi-web",
    component: UrlMenu,
    meta: {
      title: "url-menu",
      section: "url-menu",
    },
    children: [],
  },
  {
    path: "/administracion",
    name: "Administración",
    icon: "mdi-cog",
    component: Administracion,
    meta: {
      title: "Administración",
      section: "Administración",
    },
    children: [
      {
        path: "usuarios",
        name: "Usuarios",
        icon: "mdi-account-tie",
        component: () =>
          lazyLoad(import("@/views/Administracion/Usuarios/Users.vue")),
        meta: {
          title: "Usuarios",
          button: "Usuarios",
          section: "Administración",
          module: "Usuarios",
        },
      },
      {
        path: "usuarios/detalle/:id",
        name: "Detalles usuario",
        hidden: true,
        component: () =>
          lazyLoad(import("@/views/Administracion/Usuarios/UserDetail.vue")),
        meta: {
          title: "Detalles Usuario",
          button: "Usuarios",
          section: "Administración",
          module: "Usuarios",
        },
      },
      {
        path: "usuarios/nuevo",
        name: "Nuevo usuario",
        hidden: true,
        component: () =>
          lazyLoad(import("@/views/Administracion/Usuarios/UserNew.vue")),
        meta: {
          title: "Nuevo usuario",
          // button: "Usuarios",
          section: "Administración",
          module: "Usuarios",
          permissions: {
            module_name: "module_users",
            required_permission: "ADD",
          },
        },
      },
      {
        path: "usuarios/editar/:id",
        name: "Editar usuario",
        hidden: true,
        component: () =>
          lazyLoad(import("@/views/Administracion/Usuarios/UserEdit.vue")),
        meta: {
          title: "Editar Usuario",
          button: "Usuarios",
          section: "Administración",
          module: "Usuarios",
          permissions: {
            module_name: "module_users",
            required_permission: "WRITE",
          },
        },
      },
      {
        path: "roles",
        name: "Roles",
        icon: "mdi-hub-outline",
        component: () =>
          lazyLoad(import("@/views/Administracion/Roles/Roles.vue")),
        meta: {
          title: "Roles",
          button: "Roles",
          section: "Administración",
          module: "Roles",
        },
      },
      {
        path: "roles/detalle/:id",
        name: "Detalles rol",
        hidden: true,
        component: () =>
          lazyLoad(import("@/views/Administracion/Roles/RoleDetail.vue")),
        meta: {
          title: "Detalles Roles",
          button: "Roles",
          section: "Administración",
          module: "Roles",
        },
      },
      {
        path: "roles/editar/:id",
        name: "Editar rol",
        hidden: true,
        component: () =>
          lazyLoad(import("@/views/Administracion/Roles/RoleEdit.vue")),
        meta: {
          title: "Editar Rol",
          button: "Roles",
          section: "Administración",
          module: "Roles",
          permissions: {
            module_name: "module_roles",
            required_permission: "WRITE",
          },
        },
      },
      {
        path: "roles/crear",
        name: "Crear rol",
        hidden: true,
        component: () =>
          lazyLoad(import("@/views/Administracion/Roles/RoleNew.vue")),
        meta: {
          title: "Crear Rol",
          button: "Roles",
          section: "Administración",
          module: "Roles",
          permissions: {
            module_name: "module_roles",
            required_permission: "ADD",
          },
        },
      },
      {
        path: "ecus",
        name: "Ecus",
        icon: "mdi-memory",
        component: () =>
          lazyLoad(import("@/views/Administracion/Ecus/Ecus.vue")),
        meta: {
          title: "Ecus",
          button: "Ecus",
          section: "Administración",
          module: "Ecus",
        },
      },
      {
        path: "ecus/editar/:id",
        name: "Editar ecu",
        hidden: true,
        component: () =>
          lazyLoad(import("@/views/Administracion/Ecus/EcuEdit.vue")),
        meta: {
          title: "Editar Ecu",
          button: "Ecus",
          section: "Administración",
          module: "Ecus",
          permissions: {
            module_name: "module_ecus",
            required_permission: "WRITE",
          },
        },
      },
      {
        path: "ecus/crear",
        name: "Crear ecu",
        hidden: true,
        component: () =>
          lazyLoad(import("@/views/Administracion/Ecus/EcuNew.vue")),
        meta: {
          title: "Crear Ecu",
          button: "Ecus",
          section: "Administración",
          module: "Ecus",
          permissions: {
            module_name: "module_ecus",
            required_permission: "ADD",
          },
        },
      },
      {
        path: "distribuidores",
        name: "Distribuidores",
        icon: "mdi-sync",
        component: () =>
          lazyLoad(
            import("@/views/Administracion/Distributors/Distributors.vue")
          ),
        meta: {
          title: "Distribuidores",
          button: "Distribuidores",
          section: "Administración",
          module: "Distribuidores",
        },
      },
      {
        path: "distribuidores/editar/:id",
        name: "Editar distribuidor",
        hidden: true,
        component: () =>
          lazyLoad(
            import("@/views/Administracion/Distributors/DistributorEdit.vue")
          ),
        meta: {
          title: "Editar Distribuidor",
          button: "Distribuidores",
          section: "Administración",
          module: "Distribuidores",
          permissions: {
            module_name: "module_distributors",
            required_permission: "WRITE",
          },
        },
      },
      {
        path: "distribuidores/crear",
        name: "Crear distribuidor",
        hidden: true,
        component: () =>
          lazyLoad(
            import("@/views/Administracion/Distributors/DistributorNew.vue")
          ),
        meta: {
          title: "Crear Distribuidor",
          button: "Distribuidores",
          section: "Administración",
          module: "Distribuidores",
          permissions: {
            module_name: "module_distributors",
            required_permission: "ADD",
          },
        },
      },
      {
        path: "distribuidores/importacion",
        name: "Cargar distribuidores",
        hidden: true,
        component: () =>
          lazyLoad(
            import("@/views/Administracion/Distributors/DistributorImport.vue")
          ),
        meta: {
          title: "Cargar Distribuidores",
          button: "Distribuidores",
          section: "Administración",
          module: "Distribuidores",
          permissions: {
            module_name: "module_distributors",
            required_permission: "ADD",
          },
        },
      },
      {
        path: "referencias",
        name: "Referencias",
        icon: "mdi-link-variant",
        component: () =>
          lazyLoad(import("@/views/Administracion/References/References.vue")),
        meta: {
          title: "Referencias",
          button: "Referencias",
          section: "Administración",
          module: "Referencias",
        },
      },
      {
        path: "referencias/tipos_labor",
        name: "Tipos Labor",
        hidden: true,
        component: () =>
          lazyLoad(
            import(
              "@/views/Administracion/References/LaborTypes/LaborTypes.vue"
            )
          ),
        meta: {
          title: "Tipos Labor",
          button: "Referencias",
          section: "Administración",
          module: "Referencias",
        },
      },
      {
        path: "referencias/tipos_labor/crear",
        name: "Crear Tipo Labor",
        hidden: true,
        component: () =>
          lazyLoad(
            import(
              "@/views/Administracion/References/LaborTypes/LaborTypeNew.vue"
            )
          ),
        meta: {
          title: "Crear Tipo Labor",
          button: "Referencias",
          section: "Administración",
          module: "Referencias",
          permissions: {
            module_name: "module_references",
            required_permission: "ADD",
          },
        },
      },
      {
        path: "referencias/tipos_labor/editar/:id",
        name: "Editar Tipo Labor",
        hidden: true,
        component: () =>
          lazyLoad(
            import(
              "@/views/Administracion/References/LaborTypes/LaborTypeEdit.vue"
            )
          ),
        meta: {
          title: "Editar Tipo Labor",
          button: "Referencias",
          section: "Administración",
          module: "Referencias",
          permissions: {
            module_name: "module_references",
            required_permission: "WRITE",
          },
        },
      },
      {
        path: "referencias/tipos_labor/importacion",
        name: "Cargar Tipos Labor",
        hidden: true,
        component: () =>
          lazyLoad(
            import(
              "@/views/Administracion/References/LaborTypes/LaborTypeImport.vue"
            )
          ),
        meta: {
          title: "Cargar Tipos Labor",
          button: "Referencias",
          section: "Administración",
          module: "Referencias",
          permissions: {
            module_name: "module_references",
            required_permission: "ADD",
          },
        },
      },
      {
        path: "referencias/series",
        name: "Series",
        hidden: true,
        component: () =>
          lazyLoad(
            import("@/views/Administracion/References/Series/Series.vue")
          ),
        meta: {
          title: "Series",
          button: "Referencias",
          section: "Administración",
          module: "Referencias",
        },
      },
      {
        path: "referencias/series/crear",
        name: "Crear series",
        hidden: true,
        component: () =>
          lazyLoad(
            import("@/views/Administracion/References/Series/SerieNew.vue")
          ),
        meta: {
          title: "Crear Serie",
          button: "Referencias",
          section: "Administración",
          module: "Referencias",
          permissions: {
            module_name: "module_references",
            required_permission: "ADD",
          },
        },
      },
      {
        path: "referencias/series/editar/:id",
        name: "Editar Series",
        hidden: true,
        component: () =>
          lazyLoad(
            import("@/views/Administracion/References/Series/SerieEdit.vue")
          ),
        meta: {
          title: "Editar Serie",
          button: "Referencias",
          section: "Administración",
          module: "Referencias",
          permissions: {
            module_name: "module_references",
            required_permission: "WRITE",
          },
        },
      },
      {
        path: "referencias/series/importacion",
        name: "Cargar Series",
        hidden: true,
        component: () =>
          lazyLoad(
            import("@/views/Administracion/References/Series/SerieImport.vue")
          ),
        meta: {
          title: "Cargar Series",
          button: "Referencias",
          section: "Administración",
          module: "Referencias",
          permissions: {
            module_name: "module_references",
            required_permission: "ADD",
          },
        },
      },
      {
        path: "referencias/modelos",
        name: "Modelos",
        hidden: true,
        component: () =>
          lazyLoad(
            import("@/views/Administracion/References/Models/Models.vue")
          ),
        meta: {
          title: "Modelos",
          button: "Referencias",
          section: "Administración",
          module: "Referencias",
        },
      },
      {
        path: "referencias/modelos/crear",
        name: "Crear modelos",
        hidden: true,
        component: () =>
          lazyLoad(
            import("@/views/Administracion/References/Models/ModelNew.vue")
          ),
        meta: {
          title: "Crear Modelo",
          button: "Referencias",
          section: "Administración",
          module: "Referencias",
          permissions: {
            module_name: "module_references",
            required_permission: "ADD",
          },
        },
      },
      {
        path: "referencias/modelos/editar/:id",
        name: "Editar Modelos",
        hidden: true,
        component: () =>
          lazyLoad(
            import("@/views/Administracion/References/Models/ModelEdit.vue")
          ),
        meta: {
          title: "Editar Modelo",
          button: "Referencias",
          section: "Administración",
          module: "Referencias",
          permissions: {
            module_name: "module_references",
            required_permission: "WRITE",
          },
        },
      },
      {
        path: "referencias/modelos/importacion",
        name: "Cargar Modelos",
        hidden: true,
        component: () =>
          lazyLoad(
            import("@/views/Administracion/References/Models/ModelImport.vue")
          ),
        meta: {
          title: "Cargar Modelos",
          button: "Referencias",
          section: "Administración",
          module: "Referencias",
          permissions: {
            module_name: "module_references",
            required_permission: "ADD",
          },
        },
      },
      {
        path: "urls",
        name: "Urls",
        icon: "mdi-at",
        component: () =>
          lazyLoad(import("@/views/Administracion/Urls/Urls.vue")),
        meta: {
          title: "Urls",
          button: "Urls",
          section: "Administración",
          module: "Urls",
        },
      },
      {
        path: "urls/editar/:id",
        name: "Editar url",
        hidden: true,
        component: () =>
          lazyLoad(import("@/views/Administracion/Urls/UrlEdit.vue")),
        meta: {
          title: "Editar URL",
          button: "Urls",
          section: "Administración",
          module: "Urls",
          permissions: {
            module_name: "module_urls",
            required_permission: "WRITE",
          },
        },
      },
      {
        path: "urls/crear",
        name: "Crear url",
        hidden: true,
        component: () =>
          lazyLoad(import("@/views/Administracion/Urls/UrlNew.vue")),
        meta: {
          title: "Crear URL",
          button: "Urls",
          section: "Administración",
          module: "Urls",
          permissions: {
            module_name: "module_urls",
            required_permission: "ADD",
          },
        },
      },
      {
        path: "variables",
        name: "Variables",
        icon: "mdi-variable",
        component: () =>
          lazyLoad(import("@/views/Administracion/Variables/Variables.vue")),
        meta: {
          title: "Variables",
          button: "Variables",
          section: "Administración",
          module: "Variables",
          permissions: {
            module_name: "module_variables",
            required_permission: "WRITE",
          },
        },
      },
      {
        path: "variables/crear",
        name: "Crear variable",
        hidden: true,
        component: () =>
          lazyLoad(import("@/views/Administracion/Variables/VariableNew.vue")),
        meta: {
          title: "Crear Variable",
          button: "Variables",
          section: "Administración",
          module: "Variables",
          permissions: {
            module_name: "module_variables",
            required_permission: "ADD",
          },
        },
      },
      {
        path: "variables/asignar",
        name: "Asignar variable",
        hidden: true,
        component: () =>
          lazyLoad(import("@/views/Administracion/Variables/VariableAssign.vue")),
        meta: {
          title: "Asignar Variable",
          button: "Variables",
          section: "Administración",
          module: "Variables",
          permissions: {
            module_name: "module_variables",
            required_permission: "ADD",
          },
        },
      },
      {
        path: "variables/editar/:id",
        name: "Editar variable",
        hidden: true,
        component: () =>
          lazyLoad(import("@/views/Administracion/Variables/VariableEdit.vue")),
        meta: {
          title: "Editar Variable",
          button: "Variables",
          section: "Administración",
          module: "Variables",
          permissions: {
            module_name: "module_variables",
            required_permission: "WRITE",
          },
        },
      },
    ],
  },
  {
    path: "/perfil/info/:id",
    name: "PerfilInformacion",
    hidden: true,
    icon: "mdi-information-slab-circle-outline",
    component: () => lazyLoad(import("@/views/Perfil/ProfileDetail.vue")),
    meta: {
      title: "Información de la cuenta",
      section: "Perfil",
      module: "Perfil",
    },
  },
  {
    path: "/perfil/contacto",
    name: "Contacto",
    hidden: true,
    icon: "mdi-information-slab-circle-outline",
    component: () => lazyLoad(import("@/views/Perfil/ProfileContact.vue")),
    meta: {
      title: "Contacto",
      section: "Perfil",
      module: "Perfil",
    },
  },
  {
    path: "/perfil/usuario/:id",
    name: "PerfilUsuario",
    hidden: true,
    icon: "mdi-account",
    component: () => lazyLoad(import("@/views/Perfil/ProfileUser.vue")),
    meta: {
      title: "Usuario",
      section: "Perfil",
      module: "Perfil",
    },
  },
  {
    path: "/perfil/consentimiento/:id",
    name: "PerfilConsentimiento",
    hidden: true,
    icon: "mdi-file-document-edit-outline",
    component: () => lazyLoad(import("@/views/Perfil/ProfileConsent.vue")),
    meta: {
      title: "Consentimiento de datos",
      section: "Perfil",
      module: "Perfil",
    },
  },
];

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    icon: "mdi-home",
    meta: {
      title: "Login",
    },
  },
  {
    path: "/recover",
    name: "Recover",
    component: Recover,
    icon: "mdi-home",
    meta: {
      title: "Recuperar contraseña",
    },
  },
  {
    path: "/reset",
    name: "Reset",
    component: Reset,
    icon: "mdi-home",
    meta: {
      title: "Restablecer contraseña",
    },
  },
  {
    path: "/app",
    component: App,
    children: pages,
  },
  {
    path: "/",
    name: "Home",
    component: App,
    icon: "mdi-home",
    meta: {
      title: "Home",
    },
  },
];

const router = new VueRouter({
  routes,
});

const DEFAULT_TITLE = "ID-David Cloud";

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
