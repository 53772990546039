<template>
  <v-container>
    <v-alert type="info" :value="true" v-if="!userData.user_completed">
      <h3>
        {{ $t('user_data_alert_text_1') }}
      </h3>
      <router-link class="link" :to="`/perfil/usuario/` + userData.user_id">
        {{ $t('ir_perfil') }}
      </router-link>
    </v-alert>
    <v-alert type="info" :value="true" v-if="!userData.user_consent">
      <h3>
        {{ $t('user_data_alert_text_2') }}
      </h3>
      <router-link class="link" :to="`/perfil/consentimiento/` + userData.user_id">
        {{ $t('firmar_consentimiento') }}
      </router-link>
    </v-alert>
  </v-container>
</template>

<script>
// @ is an alias to /src
export default {
  name: "user-data-alert-item",
  components: {},
  data() {
    return {
      userData: null,
      consentSigned: false,
    }
  },
  created: function () {
    this.userData = JSON.parse(window.sessionStorage.getItem('vuex'));
  }
};
</script>

<style scoped>
.link {
  color: white
}
</style>
