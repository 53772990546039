<template>
  <div>
    <MainTitle :items="breadItems" :title="$t('software')" icon="mdi-cog" class="pb-0" />

    <v-container fluid class="pr-8 pl-8">
      <SubTitle :title="$t('buscar')" icon="mdi-magnify" class="pb-0" />
      <v-row class="pr-8 pl-8">
        <v-col xl="4" lg="4" md="4" sm="4" cols="12">
          <v-text-field v-model="filters.reference" :label="$t('referencia')" hide-details
            @keydown.enter="searchSerieTypes"></v-text-field>
        </v-col>
        <v-col xl="4" lg="4" md="4" sm="4" cols="12">
          <v-text-field v-model="filters.serie" :label="$t('numero_serie')" hide-details
            @keydown.enter="searchSerieTypes"></v-text-field>
        </v-col>
        <v-col xl="4" lg="4" md="4" sm="4" cols="12">
          <v-btn depressed block rounded class="white--text mt-4" color="background" @click="searchSerieTypes"
            :disabled="!(filters.reference || filters.serie)">
            {{ $t('buscar') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="pr-8 pl-8 my-5">
      <v-row wrap>
        <v-col xl="3" lg="3" md="3" sm="3" cols="12" v-for="laborType in laborTypesImages" :key="laborType.id">
          <software-card :srcImage="laborType.src" :altImage="laborType.alt" :softwareId="laborType.id"
            :addColors="true" @softwareCardClicked="onSelectImage" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MainTitle from "@/components/MainTitle";
import SubTitle from "@/components/SubTitle";
import { getLaborTypes, getLaborTypeImage } from "@/api/software";
import { buildQuery } from "@/utils.js";
import SoftwareCard from "@/components/SoftwareCard"

require("vue-select-image/dist/vue-select-image.css");

export default {
  name: "software-labor-types-item",

  components: {
    MainTitle,
    SubTitle,
    SoftwareCard
  },

  data() {
    return {
      breadItems: [
        {
          text: 'Software',
          disabled: true,
          href: 'software',
          id: 0
        },
      ],
      laborTypes: [],
      laborTypesImages: [],
      laborTypeSelected: {
        id: "",
        src: "",
        alt: "",
      },
      loadingImages: false,
      filters: {
        reference: null,
        serie: null,
      },
    };
  },

  computed: {},
  created() {
    this.refreshLaborTypes();
  },
  methods: {
    refreshLaborTypes() {
      this.restartValues();
      getLaborTypes(this.filters.reference, this.filters.serie)
        .then((result) => {
          this.loadingImages = true;
          this.laborTypes = result.labor_types;
          this.getLaborType();
        })
        .catch((err) => {
          this.loadingImages = false;
          console.log(err);
        });
    },
    getLaborType() {
      const promises = [];
      this.laborTypes.forEach((laborType) => {
        const promise = getLaborTypeImage(laborType.id)
          .then((response) => {
            const image = response.image;
            this.laborTypesImages.push({
              id: laborType.id,
              src: URL.createObjectURL(image),
              alt: laborType.name,
            });
          })
          .catch((err) => {
            console.log(err.message);
          });
        promises.push(promise);
      });

      Promise.all(promises)
        .then(() => {
          this.sortByAlt();
          this.loadingImages = false;
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    searchSerieTypes() {
      const url_query = buildQuery({
        reference: this.filters.reference,
        num_serie: this.filters.serie,
      });

      this.$router.push("software/serie_type?" + url_query);
    },
    onSelectImage(imageSelected) {
      this.$router.push("software/labor_type/" + imageSelected);
    },
    sortByAlt() {
      this.laborTypesImages.sort((a, b) => {
        if (a.alt < b.alt) {
          return -1;
        }
        if (a.alt > b.alt) {
          return 1;
        }
        return 0;
      });
    },
    restartValues() {
      this.laborTypes = [];
      this.laborTypesImages = [];
      this.laborTypeSelected = {
        id: "",
        src: "",
        alt: "",
      };
      this.loadingImages = false;
    },
  },
};
</script>

<style lang="scss">
.vue-select-image__thumbnail--selected {
  background-color: #ea183c;
}

.vue-select-image__img {
  cursor: pointer !important;
}
</style>