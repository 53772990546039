<template>
  <div>
    <ConfirmDialog ref="confirm"></ConfirmDialog>
    <ConfirmDialog ref="warning"></ConfirmDialog>

    <div>
      <MainTitle :items="breadItems" title="Distribuidores" icon="mdi-account-group" />

      <v-container fluid class="pr-8 pl-8">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col xl="3" lg="3" md="6" sm="6">
                <v-btn-toggle dense class="mt-4" v-model="filters.estado" @change="refreshAllDistributors">
                  <v-btn v-for="(estado, key) in filters.estados" depressed class="rounded-pill menu black--text"
                    :class="[filters.estado === estado ? 'primary white--text' : 'menu black--text']"
                    :key="'filterState' + key" :value="estado">
                    {{ $t(estado.toLowerCase()) }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>

              <v-spacer></v-spacer>

              <v-col xl="3" lg="3" md="6" sm="6" cols="12">
                <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('buscar')" single-line
                  hide-details></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>

          <v-data-table :headers="table.headers" :items="table.items" :options="table.options" :loading="table.loading"
            :search="search" loading-text="Cargando datos..." no-data-text="No se han encontrado distribuidores."
            no-results-text="No se han encontrado distribuidores." class="elevation-0 " color="accent" :footer-props="{
              'items-per-page-text': $t('paginas_fila'),
              pageText: '{0}-{1} de {2}',
            }">
            <template v-slot:[`item.person.name`]="{ item }">
              <router-link v-if="userData.user_consent" :to="`/distribuidor/detalle/${item.id}`">
                {{ formatClientsName(item) }}
              </router-link>
              <span v-else>{{ formatClientsName(item) }}</span>
            </template>

            <template v-slot:[`item.person.phone`]="{ item }">
              <a v-bind:href="'tel:' + item.person.phone">{{
                item.person.phone
              }}</a>
            </template>

            <template v-slot:[`item.person.email`]="{ item }">
              <a v-bind:href="'mailto:' + item.person.email">{{
                item.person.email
              }}</a>
            </template>

            <template v-slot:[`item.person.active`]="{ item }">
              <span :class="item.person.active ? 'success--text' : 'primary--text'">
                {{ getActive(item.person.active) }}
              </span>
            </template>
          </v-data-table>
        </v-card>
      </v-container>
    </div>
  </div>
</template>

<script>
import MainTitle from "@/components/MainTitle";
import { getDistributors, getUserInfo } from "@/api/users";
import ConfirmDialog from "@/components/ConfirmDialog";
import { formatClientName } from "@/utils"

export default {
  name: "distributors-item",

  components: {
    MainTitle,
    ConfirmDialog,
  },

  data() {
    return {
      breadItems: [
        {
          text: 'Distribuidores',
          disabled: true,
          href: 'distribuidor',
          id: 0
        },
      ],
      search: "",
      table: {
        loading: true,
        options: {
          itemsPerPage: 15,
        },
        headers: [],
        items: [],
        selected: [],
      },
      errMsg: null,
      filters: {
        estados: ["Activo", "Inactivo"],
        estado: "Activo",
      },
      permissions: {
        READ: 1,
        WRITE: 2,
        ADD: 4,
        REMOVE: 8,
        user_id: null,
        user_type: null,
        role: null,
        // seeDistributors: false,
        seeClients: false,
        editClients: false,
        seeMachines: false,
        msgClients: null,
        msgMachines: null,
      },
      userData: null
    };
  },

  computed: {},
  created() {
    this.checkPermissions();
    this.userData = JSON.parse(window.sessionStorage.getItem('vuex'));
  },
  methods: {
    getActive(active) {
      return active ? "Activo" : "Inactivo";
    },
    refreshAllDistributors() {
      this.table.loading = true
      getDistributors(this.filters.estado === "Activo" ? "active" : "inactive")
        .then((result) => {
          this.table.items = result.distributors;
          this.refreshTexts()
        })
        .catch((err) => {
          this.errMsg = err;
          console.log(err);
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    checkPermissions: function () {
      getUserInfo()
        .then((response) => {
          this.permissions.user_id = response.user.id;
          this.permissions.role = response.user.id;
          this.permissions.user_type = response.user.type;
          this.refreshAllDistributors();
        })
        .catch((err) => {
          this.errMsg = err;
          console.log(err);
        });
    },
    refreshTexts() {
      this.table.headers = [
        { text: this.$t("nombre"), value: "person.name" },
        { text: this.$t("telefono"), value: "person.phone" },
        { text: this.$t("email"), value: "person.email" },
        { text: this.$t("flota"), value: "machine_count" },
        { text: this.$t("poblacion"), value: "person.city" },
        { text: this.$t("pais"), value: "person.country" },
        { text: this.$t("estado"), value: "person.active" },
      ]
    },
    formatClientsName(client) {
      return formatClientName(client)
    },
  },
  watch: {
    '$i18n.locale': function () {
      this.refreshTexts()
    }
  }
};
</script>