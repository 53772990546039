<template>
  <v-card class="ma-0" @click="handleClick">
    <v-card-title class="background rounded-xl white--text justify-center" style="position: relative; z-index: 2;">
      <p v-if="translateName" class="text-center">{{ $t(`software_texts.${getStringSnakeCase(altImage)}`) }}</p>
      <p v-else class="text-center">{{ altImage }}</p>
    </v-card-title>
    <v-card-actions v-if="addColors" class="pr-8 mt-n7" style="position: relative; z-index: 3;">
      <v-icon x-large :class="`machineGamaColors${softwareId}--text`">mdi-circle</v-icon>
      <v-icon v-if="softwareId === 3" x-large class="machineGamaColors6--text ml-n4">mdi-circle</v-icon>
    </v-card-actions>
    <v-card-text :class="`pa-0 mt-n${addColors ? '7' : '0'}`" style="position: relative; z-index: 1;">
      <v-img class="rounded-xl" contain :src="srcImage" max-height="250px" />
    </v-card-text>
  </v-card>
</template>
<script>
import { getStringSnakeCase } from '@/utils';
export default {
  name: "software-card-item",
  props: {
    softwareId: {
      type: Number,
      required: false
    },
    srcImage: {
      type: String,
      required: true,
    },
    altImage: {
      type: String,
      required: true
    },
    addColors: {
      type: Boolean,
      required: false,
      default: false
    },
    translateName: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    handleClick() {
      this.$emit('softwareCardClicked', this.softwareId)
    },
    getStringSnakeCase
  }
}
</script>