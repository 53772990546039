<template>
  <div>
    <v-menu transition="slide-y-transition" bottom :offset-y="true">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="btn-settings truncate" dark icon v-bind="attrs" v-on="on">
          <v-icon large class="white--text">mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="settings">
          <v-list-item-title>{{$t('perfil')}}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="contact">
          <v-list-item-title>{{$t('contacto')}}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-title>{{$t('salir')}}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { logout } from "./login/Login.js";

export default {
  name: 'SettingsSelector',
  data() {
    return {

    }
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    settings: function () {
      this.$router.push('/perfil/info/' + this.userId)
    },
    contact: function () {
      this.$router.push('/perfil/contacto')
    },
    logout: function () {
      logout()
        .then(() => {
          this.$store.commit("setAuthentication", false);
          this.$store.commit("logout");
          this.$router.replace({ name: "Login" });
        })
        .catch(() => { });
    },
  }
}
</script>

<style>
/* Puedes agregar estilos adicionales según sea necesario */
</style>
