<template>
  <v-form ref="loginForm">
    <v-row no-gutters class="primary white--text text-uppercase font-weight-bold rounded-xxl pa-3 mb-6">
      <v-col>
        <h2>{{ header }}</h2>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-text-field v-model="username" :placeholder="$t('usuario')" outlined prepend-inner-icon="mdi-account-outline"
          type="text" required :disabled="loading" background-color="white" class="primary--text rounded-xxl" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-text-field v-model="password" :placeholder="$t('contrasena')" outlined density="compact"
        prepend-inner-icon="mdi-lock" :type="show ? 'text' :
          'password'" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show = !show" required
        :disabled="loading" background-color="white" class="primary--text rounded-xxl" />
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-alert class="text-center" :value="this.error" outlined text color="red" dismissible @input="closeAlert">
          <v-icon color="red" class="pb-1"> mdi-alert </v-icon>
          {{ this.error_msg }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row no-gutters class="align-center justify-center">
      <v-col xl="8" lg="8" md="8" sm="8" cols="12">
        <span @click="recoverPassword" class="white--text link-recover align-center">
          {{ msjRecoverPassword }}
        </span>
      </v-col>
      <v-col xl="4" lg="4" md="4" sm="4" cols="12" class="mt-xl-0 mt-lg-0 mt-md-0 mt-sm-0 mt-4">
        <v-btn block x-large rounded depressed class="transparent white--text px-4 justify-center"
          style="border: 1px solid #888888 !important;" type="submit" @click="validate">
          {{ $t('login') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-6">
      <p class="white--text">{{ $t('continuar_aceptas') }} <a class="white--text"
          href="https://id-david.com/aviso-legal/">{{ $t('terminos_uso') }}</a> {{ $t('confirmar_texto') }} <a
          class="white--text" href="https://id-david.com/politica-de-privacidad/">{{ $t('politica_privacidad') }}</a> {{
            $t('de_idavid') }}.</p>
    </v-row>
  </v-form>
</template>

<script>
import { login, user_info } from "./Login.js";

export default {
  name: "login-item",
  props: {
    // is the title displayed in the form
    header: {
      type: String,
      default: "Iniciar sesión",
    },
  },
  data: () => ({
    loading: false,
    error: false,
    error_msg: null,
    show: false,
    username: "",
    password: "",
    msjRecoverPassword: "",
    msjAcceptation: ""
  }),
  computed: {
    loadingBar: function () {
      return this.loading ? "primary" : false;
    },
  },
  created: function () {
    const errorMessage = this.$route.query.message;
    if (errorMessage) {
      this.error = true;
      this.error_msg = errorMessage
    }
    this.refreshTexts()
  },
  methods: {
    validate(event) {
      event.preventDefault();
      const data = {
        email: this.username,
        password: this.password,
      };
      login(data)
        .then(() => {
          user_info()
            .then((response) => {
              if (response.user) {
                const user = response.user;
                this.$store.commit("setUsername", user.username);
                this.$store.commit("setPrivileges", user.role);
                this.$store.commit("setUserId", user.id);
                this.$store.commit("setUserConsent", user.consent);
                this.$store.commit("setUserCompleted", user.completed);
                this.$store.commit("setType", user.type);
                this.$store.commit("setClientId", user.company_id);
                this.$store.commit("setAuthentication", true);
                this.$store.commit("setDistributor", user.type === 'Distribuidor');
              }
            })
            .finally(() => {
              if (this.$store.state.authenticated) {
                this.$router.replace({ name: "Escritorio" });
              }
            });
        })
        .catch((resp) => {
          this.error = true;
          this.error_msg = this.$t(resp);
          this.password = "";
        });
      return false;
    },
    recoverPassword() {
      this.$router.push("/recover");
    },
    closeAlert() {
      this.error = false;
      this.error_msg = null;
    },
    refreshTexts() {
      this.msjRecoverPassword = this.$t('problemas_iniciar_sesion')
      this.msjAcceptation = this.$t('problemas_iniciar_sesion')
    }
  },
  watch: {
    '$i18n.locale': function () {
      this.refreshTexts()
    }
  }
};
</script>

<style lang="scss">
.link-recover {
  cursor: pointer;
  text-decoration: underline;
}
</style>