<template>
  <v-row class="text-start ma-2" justify="start">
    <v-col cols="12" xl="12" lg="12" md="12" sm="12">
      <v-breadcrumbs v-if="items != null" :items="items">
        <template v-slot:divider>
          <v-icon>mdi-arrow-right</v-icon>
        </template>
        <template v-if="items != null" v-slot:item="{ item }">
          <v-breadcrumbs-item :disabled="item.disabled">
            <router-link style="text-decoration: none;" class="secondaryLight--text" :to="item.href">
              <h2 :class="{ 'text-left': true, 'secondary--text': !item.disabled, 'linkh2': true }"><v-icon
                  v-if="item.id === 0" large class="mr-4 secondary--text" style="text"> {{ icon }}
                </v-icon>{{ $t(item.text.toLowerCase()).toUpperCase() }}</h2>
            </router-link>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <template v-else>
        <h2 :class="{ 'text-left': true, 'secondary--text': true, 'mt-4': true }"><v-icon large class="mr-4 secondary--text"
            style="text"> {{ icon }}
          </v-icon>{{ title }}</h2>
      </template>
      <v-divider class="mt-2"></v-divider>
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src

export default {
  name: "main-title-item",
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: false,
      default: null
    },
  },
};
</script>
<style scoped>
.linkh2:hover {
  color: #ea183c !important;
}
</style>