<template>
  <v-footer footer app color="background" fluid class="pa-0 elevation-3">
    <v-container fill-height fluid class="ma-0 pa-0">
      <v-row class="ma-0 pa-0" align="center">
        <v-col cols="8" class="ma-0 pa-0">
          <v-card flat title class="flex mx-0 background text-left">
            <v-card-text class="py-0 secondary--text">
              © 2022 Tecnalia
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="ma-0 pa-0" />
        <v-col cols="2" class="ma-1 mr-3 pa-0">
          <v-img
            :contain="true"
            max-height="1.2em"
            position="right center"
            transition="none"
            src="@/assets/tecnalia_logo_blanco.png"
            class="ma-1"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
// @ is an alias to /src

export default {
  name: "footer-item",
  components: {},
};
</script>

<style lang="scss">
footer {
  z-index: 150 !important;
}
</style>