<template>
  <v-container style="align-items: center;">
    <v-row>
      <v-col>
        <v-card flat outlined>
          <v-card-title :class="'justify-start rounded-xxl ma-4 ' + textColor + '--text'"
            :style="{ 'background-color': backgroundColor }">
            <span class="card-value ml-5 text-uppercase"> {{ title }} </span>
          </v-card-title>
          <v-card-text v-if="chartData.labels.length === 0">
            No hay datos
          </v-card-text>
          <v-card-text v-else>
            <div ref="plotlyChart"></div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Plotly from 'plotly.js-dist'

export default {
  name: 'statisticsChart',
  props: {
    title: {
      type: String,
      required: true
    },
    chartData: {
      type: Object,
      required: true
    },
    variable: {
      type: String,
      required: true
    },
    lineColor: {
      type: String,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    },
    textColor: {
      type: String,
      required: true
    },

  },
  data() {
    return {
      range: null,
      plot_data: {
        x: [],
        y: [],
        type: 'bar',
        line: {
          color: this.lineColor,
          width: 8
        },
        marker: {
          size: 8,
          color: this.lineColor,
          symbol: 'circle'
        }
      },
    }
  },
  created() {
    this.plot_data.x = this.chartData.labels
    this.plot_data.y = this.chartData.datasets[0].data
    const today = new Date();
    const year = today.getFullYear();
    this.$nextTick(() => {
      if (this.$refs.plotlyChart) {
        const layout = {
          xaxis: {
            title: year,
            autorange: true
          },
          yaxis: {
            title: this.variable,
          }
        };
        Plotly.newPlot(this.$refs.plotlyChart, [this.plot_data], layout);
      }
    });
  }
}
</script>
