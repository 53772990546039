<template>
  <div>
    <MainTitle :items="breadItems" title="Escritorio" icon="mdi-monitor" />
    <v-container fluid class="pr-8 pl-8" v-if="userData.user_consent">
      <v-row>
        <v-col xl="4" lg="4" md="4" sm="4" cols="12">
          <TextCardMultiple v-if="machineCardElements != null" background-color="background" textColor="white"
            label="MÁQUINAS" :value="numMachines" icon="mdi-tractor" iconColor="white" dividerColor="black"
            :cardElements=machineCardElements />
        </v-col>
        <v-col xl="4" lg="4" md="4" sm="4" cols="12">
          <TextCardMultiple v-if="numAlarms != null && alarmCardElements.length > 0" background-color="primary"
            textColor="white" label="ALARMAS" :value="numAlarms" icon="mdi-alert-outline" iconColor="white"
            dividerColor="red" :cardElements=alarmCardElements />
        </v-col>
        <v-col xl="4" lg="4" md="4" sm="4" cols="12">
          <TableHeader backgroundColor="secondaryLight" textColor="background" label="CLIENTES" :value="numClients"
            icon="mdi-account-outline" iconColor="background" />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-6" cols="12" xl="4" lg="4" md="4" sm="12">
          <v-row v-if="numMachines > 0">
            <v-col cols="12" xl="12" lg="12" md="12" sm="12">
              <ChartPie :title="$t('maquinas_segun_gama')" :chartData="chartData" :loading="loadingPieChart"
                :error="errorPieChart"></ChartPie>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" xl="8" lg="8" md="8" sm="12">
          <v-row>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12">
              <alarm-table :activeAlarms="activeAlarms" :userData="userData" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12">
              <machine-activate-table :machinesPendingActivate="machinesPendingActivate" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12">
              <maintenance-table :user-data="userData" :tableTitle="$t('maintenance_table.proximos_mantenimientos')"
                :maintenances="nextMaintenancesTable" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="pb-8">
        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
          <bar-chart v-if="loadingBarChart === false" :title="$t('numero_maquinas_activas')" :chartData=barChartData
            :variable="$t('numero_maquinas')" :lineColor="'black'" :backgroundColor="'black'" :textColor="'white'" />
        </v-col>
        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
          <bar-chart v-if="loadingAlarmsSolvedChart === false" :title="$t('numero_alarmas_solucionadas')"
            :chartData=alarmsSolvedChartData :variable="$t('numero_alarmas')" :lineColor="'rgb(205,17,62)'"
            :backgroundColor="'rgb(205,17,62)'" :textColor="'white'" />
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <h2>{{ $t('no_informacion_consentimiento') }}</h2>
    </v-container>
  </div>
</template>

<script>
import {
  getNumMachines,
  getNumMachinesGama,
  getNextMaintenances,
  getMachinesPendingActivate,
  getActivatedMachinesLastYear,
  getUnregisteredMachines
} from "@/api/machines";
import {
  getNumAlarms,
  getActiveAlarms,
  getSolvedAlarmsLastYear,
} from "@/api/alarms";
import { getNumClients } from "@/api/clients";

import MainTitle from "@/components/MainTitle";
import TableHeader from "@/components/TableHeader";
import TextCardMultiple from "@/components/TextCardMultiple";
import AlarmTable from "@/components/AlarmTable";
import MaintenanceTable from "@/components/MaintenanceTable.vue";
import MachineActivateTable from "@/components/MachineActivateTable.vue";
import BarChart from "@/components/BarChart.vue";
import ChartPie from "@/components/ChartPie";
import moment from "moment";

export default {
  name: "desktop-item",
  components: {
    MainTitle,
    TableHeader,
    TextCardMultiple,
    ChartPie,
    AlarmTable,
    MaintenanceTable,
    MachineActivateTable,
    BarChart
  },

  data() {
    return {
      breadItems: [
        {
          text: 'Escritorio',
          disabled: true,
          href: 'escritorio',
          id: 0
        }
      ],
      userData: null,
      numMachines: "-",
      numMachinesGama: [],
      numAlarms: null,
      numClients: "-",
      chartData: {
        labels: [],
        datasets: [
          {
            label: "",
            backgroundColor: [],
            data: [],
          },
        ],
      },
      numMachinesLastYear: [],
      loadingPieChart: false,
      errorPieChart: null,
      barChartData: {
        labels: [],
        datasets: [
          {
            label: this.$t("numero_maquinas_ultimo_ano"),
            backgroundColor: ["#aad4f0"],
            data: [],
          },
        ],
      },
      loadingBarChart: true,
      errorBarChart: null,
      alarmsSolvedChartData: {
        labels: [],
        datasets: [
          {
            label: "Nº alarmas solucionadas en el último año",
            backgroundColor: ["#E46651"],
            data: [],
          },
        ],
      },
      numAlarmsSolvedLastYear: [],
      loadingAlarmsSolvedChart: false,
      errorAlarmsSolvedChart: null,
      activeAlarms: {
        loading: true,
        options: {
          itemsPerPage: 5,
        },
        headers: [],
        items: [],
      },
      nextMaintenancesTable: {
        loading: true,
        options: {
          itemsPerPage: 5,
        },
        headers: [],
        items: [],
      },
      machinesPendingActivate: {
        table: {
          loading: true,
          options: {
            itemsPerPage: 5,
          },
          headers: [],
          items: [],
        },
      },
      alarmCardElements: [],
      machineCardElements: [],
      unregisteredMachinesLength: [],
    };
  },
  methods: {
    refreshList: function () {
      this.refreshNumMachines();
      this.refreshNumMachinesGama();
      this.refreshNumAlarms();
      this.refreshNumManintenanceAlarms();
      this.refreshNumFaultAlarms();
      this.refreshNumClients();
      this.refreshActiveAlarms();
      this.refreshNextMaintenances();
      this.refreshUnregisteredMachines();
      this.refreshActivatedMachinesLastYear();
      this.refreshSolvedAlarmsLastYear();
    },
    refreshNumMachines: function () {
      getNumMachines()
        .then((response) => {
          this.numMachines = response.numMachines;
        })
        .catch(() => {
          console.log("Error obteniendo número de máquinas");
        });
    },
    refreshUnregisteredMachines: function () {
      getUnregisteredMachines()
        .then((response) => {
          this.unregisteredMachinesLength = response
          this.machineCardElements = []
          this.machineCardElements.push({
            "icon": "mdi-registered-trademark",
            "label": "PENDIENTE REGISTRO",
            "value": this.unregisteredMachinesLength
          })
          this.refreshMachinePendingActivate()
        })
        .catch(() => {
          console.log("Error obteniendo máquinas no registradas.")
        })
    },
    getGamaColors: function (labels) {
      const gamaColors = {
        'Abonado y protección de cultivos': '#d88447',
        'Equipos auxiliares': '#7d6093',
        'Pre-poda, poda y deshojado': '#578938',
        'Preparación del suelo y desherbaje': '#cb5455',
        'Tratamientos de los restos de poda': '#4072ae',
      }
      let colorList = []
      labels.forEach(label => {
        if (gamaColors[label]) {
          colorList.push(gamaColors[label])
        }
      })
      return colorList
    },
    refreshNumMachinesGama: function () {
      getNumMachinesGama()
        .then((response) => {
          this.loadingPieChart = true;
          this.numMachinesGama = response.numMachinesGama;
          const labels = this.numMachinesGama.map(
            (item) => Object.keys(item)[0]
          );
          const counts = this.numMachinesGama.map(
            (item) => Object.values(item)[0]
          );
          const colors = this.getGamaColors(labels)
          const translatedLabels = this.getTranslatedLabels(labels)
          this.chartData = {
            labels: translatedLabels,
            datasets: [
              {
                label: "Nº máquinas",
                backgroundColor: colors,
                data: counts,
              },
            ],
          };
          this.errorPieChart = null;
        })
        .catch((e) => {
          this.errorPieChart = "No se han podido obtener los datos";
          console.log("Error obteniendo número de máquinas" + e);
        })
        .finally(() => {
          this.loadingPieChart = false;
        });
    },
    refreshNumAlarms: function () {
      getNumAlarms()
        .then((response) => {
          this.numAlarms = response.numAlarms;
        })
        .catch(() => {
          console.log("Error obteniendo número de alarmas");
        });
    },
    refreshNumManintenanceAlarms: function () {
      getNumAlarms("Mantenimiento")
        .then((response) => {
          this.alarmCardElements.push({
            "icon": "mdi-wrench-outline",
            "label": "MANTENIMIENTO",
            "value": response.numAlarms
          })
        })
        .catch(() => {
          console.log("Error obteniendo número de alarmas de mantenimiento");
        });
    },
    refreshNumFaultAlarms: function () {
      getNumAlarms("Alerta")
        .then((response) => {
          this.alarmCardElements.push({
            "icon": "mdi-close-circle-outline",
            "label": "ALERTAS",
            "value": response.numAlarms
          })
        })
        .catch(() => {
          console.log("Error obteniendo número de alarmas de fallo");
        });
    },
    refreshNumClients: function () {
      getNumClients()
        .then((response) => {
          this.numClients = response.numClients;
        })
        .catch(() => {
          console.log("Error obteniendo número de clientes");
        });
    },
    refreshActiveAlarms: function () {
      getActiveAlarms()
        .then((response) => {
          this.activeAlarms.items = response.active_alarms;
          this.activeAlarms.headers = [
            { text: '#', value: "id" },
            { text: this.$t('alarm_table_headers.machine_number'), value: "machine_number" },
            { text: this.$t('alarm_table_headers.description'), value: "description" },
            { text: this.$t('alarm_table_headers.alarm_date'), value: "alarm_date" },
            { text: this.$t('alarm_table_headers.client'), value: "client" },
          ];
          for (const element of this.activeAlarms.items) {
            if (element.alarm_date.length > 0)
              element.alarm_date = moment(element.alarm_date);
            else element.alarm_date = null;
          }
        })
        .catch((err) => {
          console.log(err);
          this.activeAlarms.items = [];
        })
        .finally(() => {
          this.activeAlarms.loading = false;
        });
    },
    refreshNextMaintenances: function () {
      getNextMaintenances()
        .then((response) => {
          this.nextMaintenancesTable.items = response.next_maintenances;
          this.nextMaintenancesTable.headers = [
            { text: "#", value: "id" },
            { text: this.$t('maintenance_table.machine_number'), value: "machine_number" },
            { text: this.$t('maintenance_table.model'), value: "model" },
            { text: this.$t('maintenance_table.after'), value: "machine.hours" },
            { text: this.$t('maintenance_table.type'), value: "alarm.description" },
          ]
        })
        .catch(() => {
          this.nextMaintenancesTable.items = [];
        })
        .finally(() => {
          this.nextMaintenancesTable.loading = false;
        });
    },
    refreshMachinePendingActivate: function () {
      getMachinesPendingActivate()
        .then((response) => {
          this.machinesPendingActivate.table.items =
            response.machines_pending_activate;
          this.machinesPendingActivate.table.headers = [
            { text: this.$t('machine_activation_table.machine_number'), value: "machine_number" },
            { text: this.$t('machine_activation_table.model'), value: "model.name" },
            { text: this.$t('machine_activation_table.client'), value: "client_name" },
          ]
          const pendingActivate = this.machinesPendingActivate.table.items.length
          this.machineCardElements.push({
            "icon": "mdi-file-document",
            "label": "PENDIENTE ACTIVACIÓN",
            "value": pendingActivate
          })
        })
        .catch(() => {
          this.machinesPendingActivate.table.items = [];
        })
        .finally(() => {
          this.machinesPendingActivate.table.loading = false;
        });
    },
    refreshActivatedMachinesLastYear: function () {
      getActivatedMachinesLastYear()
        .then((response) => {
          this.loadingBarChart = true;
          this.numMachinesLastYear = response.activated_machines_last_year;

          let labels = [];
          let counts = [];
          this.numMachinesLastYear.forEach((machinesMonth) => {
            let monthName = this.getMonthNameFromNumber(machinesMonth["month"]);
            if (monthName == null) {
              return;
            }
            labels.push(monthName);
            counts.push(machinesMonth["num_machines"]);
          });

          this.barChartData = {
            ...this.barChartData,
            labels: labels,
            datasets: [
              {
                label: "Nº máquinas activadas por mes",
                backgroundColor: ["background"],
                data: counts,
              },
            ],
          };
          this.errorBarChart = null;
        })
        .catch(() => {
          this.errorBarChart = "No se han podido obtener los datos";
          console.log("Error obteniendo número de máquinas");
        })
        .finally(() => {
          this.loadingBarChart = false;
        });
    },
    refreshSolvedAlarmsLastYear: function () {
      getSolvedAlarmsLastYear()
        .then((response) => {
          this.loadingAlarmsSolvedChart = true;
          this.numAlarmsSolvedLastYear = response.alarms_solved_last_year;

          let labels = [];
          let counts = [];
          this.numAlarmsSolvedLastYear.forEach((machinesMonth) => {
            let monthName = this.getMonthNameFromNumber(machinesMonth["month"]);
            if (monthName == null) {
              return;
            }
            labels.push(monthName);
            counts.push(machinesMonth["num_alarms"]);
          });

          this.alarmsSolvedChartData = {
            ...this.alarmsSolvedChartData,
            labels: labels,
            datasets: [
              {
                label: "Nº alarmas",
                backgroundColor: ["#aad4f0"],
                data: counts,
              },
            ],
          };
          this.errorBarChart = null;
        })
        .catch(() => {
          this.errorAlarmsSolvedChart = "No se han podido obtener los datos";
          console.log("Error obteniendo número de alarmas");
        })
        .finally(() => {
          this.loadingAlarmsSolvedChart = false;
        });
    },
    getMonthNameFromNumber: function (monthNumber) {
      const months = [
        this.$t("Enero"),
        this.$t("Febrero"),
        this.$t("Marzo"),
        this.$t("Abril"),
        this.$t("Mayo"),
        this.$t("Junio"),
        this.$t("Julio"),
        this.$t("Agosto"),
        this.$t("Septiembre"),
        this.$t("Octubre"),
        this.$t("Noviembre"),
        this.$t("Diciembre"),
      ];

      if (monthNumber >= 1 && monthNumber <= 12) {
        return this.$t(months[monthNumber - 1]);
      } else {
        return null;
      }
    },
    getTranslatedLabels(labels) {
      const translatedLabels = []
      labels.forEach((label) => {
        translatedLabels.push(this.$t(`pie_chart.` + label.toLowerCase()))
      })
      return translatedLabels
    },
  },
  mounted() {
    this.refreshList();
  },
  created() {
    this.userData = JSON.parse(window.sessionStorage.getItem("vuex"));
  },
  watch: {
    '$i18n.locale': function () {
      this.refreshNumMachinesGama()
      this.refreshActiveAlarms()
      this.refreshUnregisteredMachines()
      this.refreshNextMaintenances()
      this.refreshActivatedMachinesLastYear()
      this.refreshSolvedAlarmsLastYear()
    }
  }
};
</script>
