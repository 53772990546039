<template>
  <v-form ref="loginForm">
    <v-row>
      <v-col class="pt-0">
        <v-alert class="text-center" :value="this.alertShow" dismissible outlined text :color="alertColor"
          @input="closeAlert">
          <v-icon :color="alertIconColor" class="pb-1"> mdi-check-circle </v-icon>
          {{ this.alertMsg }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row no-gutters class="white--text text-uppercase font-weight-bold">
      <v-col>
        <h2>{{ header }}</h2>
      </v-col>
    </v-row>
    <v-row no-gutters class="white--text">
      <v-col>
        <p>{{ $t('mensaje_recuperar') }}</p>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-text-field v-model="email" :placeholder="$t('email')" outlined prepend-inner-icon="mdi-email-outline"
        type="text" required :disabled="loading" background-color="white" class="black--text rounded-xxl" />
    </v-row>
    <v-row no-gutters class="align-center">
      <v-col>
        <v-btn block xl="4" lg="4" md="4" sm="4" cols="12"
          class="mb-xl-0 mb-lg-0 mb-mb-0 mb-sm-0 mb-4 transparent white--text"
          style="border: 1px solid #888888 !important;" x-large depressed rounded @click="validate"
          :disabled="loading || !isValidEmail(this.email)" color="white">{{ $t('enviar')
          }}</v-btn>
      </v-col>
      <v-col class="text-end" xl="8" lg="8" md="8" sm="8" cols="12">
        <h3 @click="$router.go(-1)" class="white--text link-recover justify-end">{{
          $t('mensaje_volver_inicio_sesion')
        }}</h3>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { recover_password } from "./Login.js";

export default {
  name: "recover-item",
  props: {
    header: {
      type: String,
      default: "Recuperar contraseña",
    },
  },
  data: () => ({
    loading: false,
    alertShow: false,
    alertMsg: null,
    alertColor: null,
    alertIconColor: null,
    email: "",
  }),
  computed: {
    loadingBar: function () {
      return this.loading ? "primary" : false;
    },
  },
  methods: {
    isValidEmail(email) {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(email);
    },
    validate(event) {
      event.preventDefault();
      const data = {
        email: this.email,
      };
      recover_password(data)
        .then((response) => {
          if (response.status == "ok") {
            this.alertShow = true;
            this.alertColor = 'green';
            this.alertIconColor = 'green';
            this.alertMsg = this.$t('envio_correo_cambio_pass')
          }
        })
        .catch((err) => {
          this.alertShow = true;
          this.alertColor = 'red';
          this.alertIconColor = 'red';
          this.alertMsg = err.message;
        });
      return false;
    },
    closeAlert() {
      this.alertShow = false;
      this.alertMsg = null;
    },
  },
};
</script>
<style>
.link-recover {
  cursor: pointer;
  text-decoration: underline;
}
</style>