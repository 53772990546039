import Vue from "vue";
import fetchIntercept from "fetch-intercept";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./locale/i18n";
import { user_info } from "./components/login/Login.js";
import {
  getMenuPermissions,
  getPermissionByRoleName,
} from "@/api/permissions.js";

Vue.config.productionTip = false;

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

router.beforeEach(async function (to, _from, next) {
  if (store.state.authenticated) {
    await store.dispatch("refreshSession");
  }

  const logStatus = store.state.authenticated;
  if (logStatus) {
    if (to.name === "Login" || to.name === "Recover" || to.name === "Reset") {
      next({ name: "Escritorio" });
      return;
    }
    if (!to.name || to.name == "Home") {
      next({ name: "Escritorio" });
      return;
    }
    if (to.name == "Escritorio") {
      next();
      return;
    }
    let sectionPermissions = ["Escritorio"];

    const userData = JSON.parse(window.sessionStorage.getItem("vuex"));

    getMenuPermissions(store.state.role, userData.user_id)
    .then((response) => {
        const menu_permission = response.menu_permission;
        sectionPermissions = Object.keys(menu_permission);
        if (
          sectionPermissions.includes(to.meta.section) &&
          (to.meta.module == to.meta.section ||
            menu_permission[to.meta.section].includes(to.meta.module))
          ) {
          if ("permissions" in to.meta) {
            const actionsPermissions = {
              NONE: 0,
              READ: 1,
              WRITE: 2,
              ADD: 4,
              REMOVE: 8,
            };
            getPermissionByRoleName(store.state.role)
            .then((response) => {
                const permission = response.permission;
                const actionValue =
                  actionsPermissions[to.meta.permissions.required_permission];
                const access =
                  (permission[to.meta.permissions.module_name] &
                    actionValue) ===
                  actionValue;

                if (access) {
                  next();
                  return;
                } else {
                  next({ name: "Escritorio" });
                  return;
                }
              })
              .catch((err) => {
                console.log(err);
                next({ name: "Escritorio" });
                return;
              });
          }
          next();
          return;
        }
        next({ name: "Escritorio" });
        return;
      })
      .catch((error) => {
        console.log(error);
        next({ name: "Escritorio" });
        return;
      });
  } else if (!store.state.backend_verify) {
    user_info()
      .then((response) => {
        if (response.user) {
          const user = response.user;
          store.commit("setUsername", user.username);
          store.commit("setPrivileges", user.role);
          store.commit("setUserId", user.id);
          store.commit("setUserConsent", user.consent);
          store.commit("setUserCompleted", user.completed);
          store.commit("setType", user.type);
          store.commit("setClientId", user.company_id);
          store.commit("setAuthentication", true);
          next({ name: "Escritorio" });
        }
      })
      .catch(() => {
        store.commit("setBackendVerify", true);
        if (to.name == "Reset") {
          next();
          return;
        }
        next({ name: "Login" });
      });
  } else {
    if (to.name == "Login" || to.name == "Recover" || to.name == "Reset") {
      next();
    } else {
      next({ name: "Login" });
    }
  }
});

fetchIntercept.register({
  request: function (url, config) {
    if (!config) {
      config = {};
    }
    if (!config.headers) {
      config.headers = {};
    }

    config.headers["Accept"] = "application/json";
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/json";
    }
    if (config.headers["Content-Type"] == "multipart/form-data") {
      delete config.headers["Content-Type"];
    }
    config.credentials = "same-origin";

    if (
      config.method &&
      config.method != "GET" &&
      url != "/api/accounts/login" &&
      store.state.token
    ) {
      config.headers["X-CSRFToken"] = store.state.token;
    }
    return [url, config];
  },
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
  created() {
    setInterval(() => {
      this.$store.dispatch("checkTokenExpiry");
    }, 60000);
  }
}).$mount("#app");
