import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    themes: {
      light: {
        primary: "#ea183c",
        secondary: "#888888",
        accent: "#f44336",
        error: "#c98000",
        warning: "#EED202",
        info: "#3c81f0",
        success: "#60b946",
        background: "#000000",
        secondaryLight: "#D3D3D3",
        purple: "#a69fbf",
        blueCustom: "#0032B3",
        severity0: "#4caf50",
        severity1: "#fb8c00",
        severity2: "#ff5252",
        machineGamaColors1: "#E0C005",
        machineGamaColors2: "#7F6194",
        machineGamaColors3: "#318A36",
        machineGamaColors4: "#E85455",
        machineGamaColors5: "#0073B3",
        machineGamaColors6: "#81B632",
      },
    },
  },
});
