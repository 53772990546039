<template>
  <div>
    <Header :showSettingsSelect="true" />

    <v-main>
      <UserDataAlert />
      <router-view />
    </v-main>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import UserDataAlert from "@/components/UserDataAlert.vue";

export default {
  name: "App",

  components: {
    Footer,
    Header,
    UserDataAlert
  },
};
</script>
