import { buildQuery } from "@/utils.js";

export async function getLaborTypes(reference, num_serie) {
  const url_query = buildQuery({
    reference: reference,
    num_serie: num_serie,
  });

  const url = "/api/software/labor_types?" + url_query;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error get labor types info");
  }
}

export async function getLaborType(id_labor_type) {
  const url = "/api/software/labor_type/" + id_labor_type;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("The labor type entered does not exist");
  }
}

export async function getLaborTypeImage(id_labor_type) {
  const url = "/api/software/labor_type/image/" + id_labor_type;
  const response = await fetch(url, {
    method: "GET",
  });

  if (response.status == 200) {
    const filename = response.headers
      .get("Content-Disposition")
      .split("filename=")[1];
    const imageBlob = await response.blob();

    return { filename: filename, image: imageBlob };
  } else {
    throw new Error("The labor type image does not exist");
  }
}

export async function createLaborType(data) {
  const url = "/api/software/labor_type";
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error creando tipo labor");
  }
}

export async function updateLaborType(id, labor_type, file) {
  const url = "/api/software/labor_type/" + id;
  const formData = new FormData();
  formData.append("labor_type", JSON.stringify(labor_type));
  formData.append("image", file);

  const response = await fetch(url, {
    method: "PUT",
    body: formData,
    headers: {
      //Luego se elimina, es para tener trazada la peticion
      "Content-Type": "multipart/form-data",
    },
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error actualizando el tipo de labor");
  }
}

export async function getLaborTypesTemplate() {
  const url = "/api/software/labor_type/template";
  const response = await fetch(url, {
    method: "GET",
  });

  if (response.status == 200) {
    const filename = "tiposLaborPlantilla.csv";
    const fileBlob = await response.blob();

    // Crear un enlace temporal para descargar el archivo
    const link = document.createElement("a");

    // Convertir el archivo Blob a una cadena CSV
    const csvString = await fileBlob.text();

    // Crear un objeto Blob a partir de la cadena CSV
    const csvBlob = new Blob([csvString], { type: "text/csv" });

    // Configurar los atributos del enlace para descargar el archivo CSV
    link.href = URL.createObjectURL(csvBlob);
    link.download = filename;
    link.click();

    // Liberar recursos del enlace temporal
    URL.revokeObjectURL(link.href);
    return true;
  } else {
    throw new Error("Error to get the labor types template");
  }
}

export async function importLaborTypes(file) {
  const url = "/api/software/labor-type/import";
  const formData = new FormData();
  formData.append("file", file);

  const response = await fetch(url, {
    method: "POST",
    body: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  if (response.status == 200) {
    return response.json();
  } else {
    const err = await response.json();
    throw new Error(err.error);
  }
}

export async function deleteLaborType(id_labor_type) {
  const url = "/api/software/labor_type/" + id_labor_type;
  const response = await fetch(url, {
    method: "DELETE",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error eliminando tipo de labor");
  }
}

// Series
export async function getSeries() {
  const url = "/api/software/series";
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error get series info");
  }
}

export async function getSerie(id_serie) {
  const url = "/api/software/serie/" + id_serie;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("The serie entered does not exist");
  }
}

export async function getSerieImage(id_serie) {
  const url = "/api/software/serie/image/" + id_serie;
  const response = await fetch(url, {
    method: "GET",
  });

  if (response.status == 200) {
    const filename = response.headers
      .get("Content-Disposition")
      .split("filename=")[1];
    const imageBlob = await response.blob();

    return { filename: filename, image: imageBlob };
  } else {
    throw new Error("The serie image does not exist");
  }
}

export async function getSeriesLabor(id_labor_type) {
  const url = "/api/software/series-labor/" + id_labor_type;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error get serie types info");
  }
}

export async function getSerieTypeImage(id_serie_type) {
  const url = "/api/software/serie_type/image/" + id_serie_type;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    const imageBlob = response.blob();
    return imageBlob;
  } else {
    throw new Error("The serie type entered does not exist");
  }
}

export async function getSeriesReference(reference, num_serie) {
  const url_query = buildQuery({
    reference: reference,
    num_serie: num_serie,
  });

  const url = "/api/software/serie_type_reference?" + url_query;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error get serie types info by reference");
  }
}

export async function updateSerie(id, serie, file) {
  const url = "/api/software/serie/" + id;
  const formData = new FormData();
  formData.append("serie", JSON.stringify(serie));
  formData.append("image", file);

  const response = await fetch(url, {
    method: "PUT",
    body: formData,
    headers: {
      //Luego se elimina, es para tener trazada la peticion
      "Content-Type": "multipart/form-data",
    },
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error actualizando la serie");
  }
}

export async function createSerie(data, file) {
  const url = "/api/software/serie";
  const formData = new FormData();
  formData.append("serie", JSON.stringify(data));
  formData.append("image", file);
  const response = await fetch(url, {
    method: "POST",
    body: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error creando serie");
  }
}

export async function getSeriesTemplate() {
  const url = "/api/software/serie/template";
  const response = await fetch(url, {
    method: "GET",
  });

  if (response.status == 200) {
    const filename = "seriesPlantilla.csv";
    const fileBlob = await response.blob();

    // Crear un enlace temporal para descargar el archivo
    const link = document.createElement("a");

    // Convertir el archivo Blob a una cadena CSV
    const csvString = await fileBlob.text();

    // Crear un objeto Blob a partir de la cadena CSV
    const csvBlob = new Blob([csvString], { type: "text/csv" });

    // Configurar los atributos del enlace para descargar el archivo CSV
    link.href = URL.createObjectURL(csvBlob);
    link.download = filename;
    link.click();

    // Liberar recursos del enlace temporal
    URL.revokeObjectURL(link.href);
    return true;
  } else {
    throw new Error("Error to get the series template");
  }
}

export async function importSeries(file) {
  const url = "/api/software/serie/import";
  const formData = new FormData();
  formData.append("file", file);

  const response = await fetch(url, {
    method: "POST",
    body: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  if (response.status == 200) {
    return response.json();
  } else {
    const err = await response.json();
    throw new Error(err.error);
  }
}

export async function deleteSerie(id_serie) {
  const url = "/api/software/serie/" + id_serie;
  const response = await fetch(url, {
    method: "DELETE",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error eliminando la serie");
  }
}

// MODELS
export async function getModels() {
  const url = "/api/software/models";
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error get models info");
  }
}

export async function getModel(id_model) {
  const url = "/api/software/model/" + id_model;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("The model entered does not exist");
  }
}

export async function createModel(data) {
  const url = "/api/software/model";
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error creando model");
  }
}

export async function updateModel(idModel, data) {
  const url = "/api/software/model/" + idModel;
  const response = await fetch(url, {
    method: "PUT",
    body: JSON.stringify(data),
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error actualizando modelo");
  }
}

export async function getModelsTemplate() {
  const url = "/api/software/model/template";
  const response = await fetch(url, {
    method: "GET",
  });

  if (response.status == 200) {
    const filename = "modelosPlantilla.csv";
    const fileBlob = await response.blob();

    // Crear un enlace temporal para descargar el archivo
    const link = document.createElement("a");

    // Convertir el archivo Blob a una cadena CSV
    const csvString = await fileBlob.text();

    // Crear un objeto Blob a partir de la cadena CSV
    const csvBlob = new Blob([csvString], { type: "text/csv" });

    // Configurar los atributos del enlace para descargar el archivo CSV
    link.href = URL.createObjectURL(csvBlob);
    link.download = filename;
    link.click();

    // Liberar recursos del enlace temporal
    URL.revokeObjectURL(link.href);
    return true;
  } else {
    throw new Error("Error to get the models template");
  }
}

export async function importModels(file) {
  const url = "/api/software/model/import";
  const formData = new FormData();
  formData.append("file", file);

  const response = await fetch(url, {
    method: "POST",
    body: formData,
    headers: {
      //Luego se elimina, es para tener trazada la peticion
      "Content-Type": "multipart/form-data",
    },
  });
  if (response.status == 200) {
    return response.json();
  } else {
    const err = await response.json();
    throw new Error(err.error);
  }
}

export async function deleteModel(id_model) {
  const url = "/api/software/model/" + id_model;
  const response = await fetch(url, {
    method: "DELETE",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error eliminando el modelo");
  }
}

// References
// Image requests
export async function getReferenceImage(type) {
  const url_query = buildQuery({
    reference_type: type,
  });
  const url = "/api/software/reference/image?" + url_query;
  const response = await fetch(url, {
    method: "GET",
  });

  if (response.status == 200) {
    const filename = response.headers
      .get("Content-Disposition")
      .split("filename=")[1];
    const imageBlob = await response.blob();

    return { filename: filename, image: imageBlob };
  } else {
    throw new Error("The reference image does not exist");
  }
}

export async function getMachineSoftwareVersionHistory(machine_id) {
    const url = "/api/software/version-history/" + machine_id;
    const response = await fetch(url, {
      method: "GET",
    });
    if (response.status == 200) {
      return response.json();
    } else {
      throw new Error("The machine entered does not exist");
    }
  }
