<template>
  <v-form ref="resetForm">
    <v-row no-gutters class="primary white--text text-uppercase font-weight-bold rounded-xxl pa-3 mb-6">
      <v-col>
        <h2>{{ header }}</h2>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-text-field v-model="password" :placeholder="$t('nueva_contrasena')" outlined prepend-inner-icon="mdi-lock"
        :type="showNewPassword ? 'text' :
          'password'" :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showNewPassword = !showNewPassword" required :disabled="loading" background-color="white"
        class="black--text rounded-xxl" />
    </v-row>
    <v-row no-gutters>
      <v-text-field v-model="passwordValidate" :placeholder="$t('repetir_contrasena')" outlined
        prepend-inner-icon="mdi-lock" :type="showRepeatPassword ? 'text' :
          'password'" :append-icon="showRepeatPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showRepeatPassword = !showRepeatPassword" required :disabled="loading" background-color="white"
        class="black--text rounded-xxl" />
    </v-row>
    <v-row>
      <v-col class="pt-0" cols="12" xl="12" lg="12" md="12" sm="12">
        <v-alert class="text-center" :value="this.error" :dismissible="dismissible" outlined text color="red"
          @input="closeAlert">
          <v-icon color="red" class="pb-1"> mdi-alert </v-icon>
          {{ this.error_msg }}
        </v-alert>
        <v-alert class="text-center" :value="this.success" dismissible outlined text color="green"
          @input="closeSuccessAlert">
          <v-icon color="success" class="pb-1"> mdi-check-circle </v-icon>
          {{ this.success_msg }}
        </v-alert>
      </v-col>
    </v-row>
    <v-card-actions class="px-4">
      <v-spacer />
      <v-btn depressed rounded class="white--text px-4 mr-2" color="background" type="button" @click="returnBack">
        <v-icon left>mdi-arrow-left</v-icon>{{ $t('volver') }}
      </v-btn>
      <v-btn depressed rounded class="white--text px-4" color="primary" :disabled="loading || !this.dismissible || !this.password || !isValidPassword()
        " type="submit" @click="validate">
        {{ $t('enviar') }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { reset_password, check_token_expired } from "./Login.js";

export default {
  name: "reset-item",
  props: {
    // is the title displayed in the form
    header: {
      type: String,
      default: "Recuperar contraseña",
    },
  },
  data: () => ({
    loading: false,
    dismissible: false,
    error: false,
    error_msg: null,
    success: false,
    success_msg: null,
    token: null,
    password: "",
    passwordValidate: "",
    showNewPassword: false,
    showRepeatPassword: null,
  }),
  computed: {
    loadingBar: function () {
      return this.loading ? "primary" : false;
    },
  },
  created() {
    this.token = this.$route.query.token;
    const tokenData = { 'token': this.token }
    check_token_expired(tokenData)
      .then((response) => {
        if (response.invalid) {
          this.error = true;
          this.error_msg = this.$t('enlace_invalido');
          this.$router.push("/login?message=" + this.error_msg)
        }
        if (response.expired) {
          this.error = true;
          this.error_msg = this.$t('token_expirado');
          this.$router.push("/login?message=" + this.error_msg)
        }
        this.dismissible = true
      })
      .catch(() => {
        this.error = true;
        this.error_msg = this.$t('enlace_invalido');
        this.$router.push("/login?message=" + this.error_msg)
      })
  },
  methods: {
    isValidPassword() {
      const regex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;

      if (
        this.password === this.passwordValidate &&
        regex.test(this.password)
      ) {
        this.closeAlert();
        return true;
      } else if (this.password == "" || this.passwordValidate == "") {
        this.closeAlert();
        return false;
      } else if (this.password != this.passwordValidate) {
        this.error = true;
        this.error_msg = this.$t('contrasena_no_coincide');
        return false;
      } else {
        this.error = true;
        this.error_msg = this.$t('contrasena_no_valida_1') +
          this.$t('contrasena_no_valida_2') +
          this.$t('contrasena_no_valida_3') +
          this.$t('contrasena_no_valida_4')
        return false;
      }
    },
    validate(event) {
      event.preventDefault();
      const data = {
        password: this.password,
        password_validate: this.passwordValidate,
        token: this.token,
      };
      reset_password(data)
        .then((response) => {
          if (response.status == "ok") {
            this.closeAlert();
            this.success = true;
            this.success_msg = "La contraseña se ha modificado correctamente.";
            this.$router.replace({ name: "Login" });
          }
        })
        .catch(() => {
          this.error = true;
          this.error_msg = "No se ha podido modificar la contraseña";
          this.password = "";
          this.passwordValidate = "";
        });
      return false;
    },
    closeAlert() {
      this.error = false;
      this.error_msg = null;
    },
    closeSuccessAlert() {
      this.success = false;
      this.success_msg = null;
    },
    returnBack() {
      if (this.dismissible) {
        this.$router.replace({ name: "Login" });
      } else {
        this.$router.replace({ name: "Recover" });
      }
    },
  },
};
</script>