<template>
  <v-card cols="12" xl="12" lg="12" md="12" sm="12">
    <v-card-title class="justify-start font-weight-bold" cols="12" xl="12" lg="12" md="12" sm="12">
      <v-icon left color="background">
        mdi-tractor
      </v-icon>
      <span class="background--text text-uppercase">
        {{ $t('machine_activation_table.maquinas_pendientes') }}
      </span>
    </v-card-title>
    <v-data-table :headers="machinesPendingActivate.table.headers" :items="machinesPendingActivate.table.items"
      :options="machinesPendingActivate.table.options" :loading="machinesPendingActivate.table.loading"
      :loading-text="$t('machine_activation_table.cargando_maquinas')"
      :no-data-text="$t('machine_activation_table.maquinas_no_encontradas')"
      :no-results-text="$t('machine_activation_table.maquinas_no_encontradas')" color="accent" :footer-props="{
        'items-per-page-text': 'Rows per page',
        pageText: '{0}-{1} de {2}',
      }">
      <template v-slot:[`item.machine_number`]="{ item }">
        <router-link :to="`/flota/maquina/detalle/${item.id}`">
          {{ item.machine_number }}
        </router-link>
      </template>
      <template v-slot:[`item.client_name`]="{ item }">
        <router-link :to="`/cliente/detalle/${item.client.id}`">
          {{ formatClientsName(item.client) }}
        </router-link>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { formatClientName } from "@/utils"

export default {
  name: "machines-activate-table",
  props: {
    machinesPendingActivate: {
      type: Object,
      required: true,
    }
  },
  methods: {
    formatClientsName(client) {
      return formatClientName(client)
    },
  }
}
</script>
<style lang="scss" scoped>
.table-title {
  color: black !important;
  text-transform: uppercase;
}

.v-data-table-header {
  background-color: rgb(205, 17, 62) !important;
  text-transform: uppercase;
}
</style>