<template>
  <div>
    <v-menu transition="slide-y-transition" bottom :offset-y="true">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="btn-settings truncate" dark icon v-bind="attrs" v-on="on">
          <v-icon class="white--text">mdi-translate</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-for="(language, index) in languages" :key="index" @click="changeLanguage(language.value)">
          <v-list-item-content>
            <v-list-item-title>{{ language.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'LanguageSelector',
  data() {
    return {
      languages: [
        { text: 'English', value: 'en' },
        { text: 'Español', value: 'es' },
      ]
    }
  },
  methods: {
    changeLanguage(language) {
      this.$i18n.locale = language;
    }
  }
}
</script>
