<template>
  <v-container fluid :class="customClass">
    <v-row align="start" justify="start">
      <v-col cols="12" xl="12" lg="12" md="12" sm="12">
        <h3 class="text-left" style="color: grey;">
          <v-icon color="secondary"> {{ icon }} </v-icon>
          {{ title }}
        </h3>
        <v-divider class="my-2"></v-divider>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: "main-title-item",
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    customClass: {
      type: String,
      required: false,
      default: "pa-8",
    },
  },
};
</script>