export async function getNumClients() {
  const url = "/api/clients/count";
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Number of clients not found");
  }
}

export function getClient(id) {
  var url = "/api/clients/client/" + id;
  return fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

//Location of a Client
export async function getClientLocation(id_client) {
  const url = "/api/clients/client_location/" + id_client;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("The client entered does not exist");
  }
}

//Machines of a Client
export async function getCllientMachines(id_client) {
  const url = "/api/clients/client_machines/" + id_client;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("The client entered does not exist");
  }
}

export function addClient(client) {
  var url = "/api/clients/client";
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(client),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export function validateCreationClient(client_name) {
  var url = "/api/clients/validate_creation_client";
  return fetch(url, {
    method: "POST",
    body: JSON.stringify({ client_name: client_name }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export async function getClientsTemplate() {
  const url = "/api/clients/template";
  const response = await fetch(url, {
    method: "GET",
  });

  if (response.status == 200) {
    const filename = "clientesPlantilla.xlsx";
    const fileBlob = await response.blob();

    // Crear un enlace temporal para descargar el archivo
    const link = document.createElement("a");

    // Configurar los atributos del enlace para descargar el archivo
    link.href = URL.createObjectURL(fileBlob);
    link.download = filename;
    link.click();

    // Liberar recursos del enlace temporal
    URL.revokeObjectURL(link.href);
    return true;
  } else {
    throw new Error("Error to get the clients template");
  }
}

export async function import_clients(file) {
  const url = "/api/clients/import";
  const formData = new FormData();
  formData.append("file", file);

  const response = await fetch(url, {
    method: "POST",
    body: formData,
    headers: {
      //Luego se elimina, es para tener trazada la peticion
      "Content-Type": "multipart/form-data",
    },
  });
  if (response.status == 200) {
    return response.json();
  } else {
    const err = await response.json();
    throw new Error(err.error);
  }
}

export function deleteClient(id) {
  var url = "/api/clients/client/" + id;
  return fetch(url, {
    method: "DELETE",
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export function updateClient(id, client) {
  var url = "/api/clients/client/" + id;
  return fetch(url, {
    method: "PUT",
    body: JSON.stringify(client),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export async function getCompanies() {
  const url = "/api/clients/companies";
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error get companies");
  }
}

export async function getClientsCrops(clientId) {
  const url = "/api/clients/client-crops/" + clientId;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error getting client crops");
  }
}

export function updateClientCrops(id, crops) {
  var url = "/api/clients/crops/" + id;
  return fetch(url, {
    method: "PUT",
    body: JSON.stringify(crops),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export async function getClientsTractors(clientId) {
  const url = "/api/clients/client-tractors/" + clientId;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error getting client tractors");
  }
}

export async function addCropsForClient(clientId, cropFields) {
  const url = "/api/clients/client-crops/";
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      client_id: clientId,
      cropFields: cropFields,
    }),
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("The crop for the client coudln't be added");
  }
}

export async function addTractorsForClient(clientId, tractorFields) {
  const url = "/api/clients/client-tractors/";
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      client_id: clientId,
      tractor_fields: tractorFields,
    }),
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("The tractor for the client coudln't be added");
  }
}

export async function getClientsTechnicalData(clientId) {
  const url = "/api/technical-data/technical-data/" + clientId;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error getting client technical data");
  }
}

export async function addTechnicalDataForClient(clientId, technicalData) {
  const url = "/api/clients/client-technical-data/";
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      client_id: clientId,
      technicalData: technicalData,
    }),
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("The technical data for the client coudln't be added");
  }
}

export function updateClientTechnicalData(id, technicalData) {
  const url = "/api/technical-data/technical-data/" + id;
  return fetch(url, {
    method: "PUT",
    body: JSON.stringify(technicalData),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export function updateClientTractors(id, tractors) {
  var url = "/api/clients/tractors/" + id;
  return fetch(url, {
    method: "PUT",
    body: JSON.stringify(tractors),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}
