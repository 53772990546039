import { buildQuery } from "@/utils.js";

export async function getCurrentUser() {
  const url = "/api/users/current-user";
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("The user entered does not exist");
  }
}

export async function getUsers(role_id, active, user_type) {
  const url_query = buildQuery({
    role_id: role_id,
    active: active,
    user_type: user_type,
  });

  const url = "/api/users/users?" + url_query;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error get users info");
  }
}

export async function getUser(id_user) {
  const url = "/api/users/user/" + id_user;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("The user entered does not exist");
  }
}

export async function addUser(user) {
  const url = "/api/users/users";
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(user),
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error update user");
  }
}

export async function updateUser(id, user, file) {
  const url = "/api/users/user/" + id;
  const formData = new FormData();
  formData.append("user", JSON.stringify(user));
  formData.append("image", file);

  const response = await fetch(url, {
    method: "PUT",
    body: formData,
    headers: {
      //Luego se elimina, es para tener trazada la peticion
      "Content-Type": "multipart/form-data",
    },
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error(response.status);
    // throw new Error("Error update user");
  }
}

export async function update_user_status(data) {
  const url = "/api/users/user-status";
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error update user status");
  }
}

export async function deleteUser(id_user) {
  const url = "/api/users/user/" + id_user;
  const response = await fetch(url, {
    method: "DELETE",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error delete user");
  }
}

export async function getUsersByRole(id_role) {
  const url = "/api/users/users-role/" + id_role;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error get user info");
  }
}

export async function getUsersByCompany(id_company) {
  const url = "/api/users/users-company/" + id_company;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error get user info");
  }
}

export async function getUsersTypes() {
  const url = "/api/users/types";
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error get user types");
  }
}

// Image requests
export async function getUserImage(image_path) {
  const url_query = buildQuery({
    image_path: image_path,
  });
  const url = "/api/users/image?" + url_query;
  const response = await fetch(url, {
    method: "GET",
  });

  if (response.status == 200) {
    const filename = response.headers
      .get("Content-Disposition")
      .split("filename=")[1];
    const imageBlob = await response.blob();

    return { filename: filename, image: imageBlob };
  } else {
    throw new Error("The user image profile does not exist");
  }
}

// Current User info
export async function getUserInfo() {
  const url = "/api/users/info";
  const response = await fetch(url, {
    credentials: "same-origin",
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error get user info");
  }
}

export async function getUserLabels(id) {
  const url = "/api/users/user-labels/" + id;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error, Request: " + response.request.url + ". Response: " + response.status + ", " + response.statusText);
  }
}

export async function addLabelForUser(user_id, label_id) {
  const url = "/api/users/user-label/";
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      user_id: user_id,
      label_id: label_id,
    }),
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("The label for the user entered does not exist");
  }
}

export function removeLabelFromUser(user_id, label_id) {
  const url_query = buildQuery({
    user_id: user_id,
    label_id: label_id,
  });
  var url = "/api/users/remove-label?" + url_query;
  return fetch(url, {
    method: "DELETE",
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export function removeUserLabels(user_id) {
  const url_query = buildQuery({
    user_id: user_id,
  });
  var url = "/api/users/remove-all-labels?" + url_query;
  return fetch(url, {
    method: "DELETE",
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export async function getActivity(id_user) {
  const url = "/api/users/activity/" + id_user;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("The user entered does not exist");
  }
}

export async function getObjectById(object_id, object) {
  const url_query = buildQuery({
    object_id: object_id,
    object: object,
  });
  const url = "/api/users/object-name?" + url_query;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("The object id entered does not exist");
  }
}

export function getObjectUrl(object_id, object) {
  const urlDict = {
    'Machine': '/flota/maquina/detalle/' + object_id,
    'Alarm': '/flota/alarma/detalle/' + object_id,
    'Client': '/cliente/detalle/' + object_id,
    'Person': '/cliente/detalle/' + object_id,
    'User': '/administracion/usuarios/detalle/' + object_id,
    'Ecu': '/administracion/ecus/editar/' + object_id,
    'Url': '/administracion/urls/editar/' + object_id,
    'Variable': '/administracion/variables/editar/' + object_id,
  }
  if (object in urlDict){
    return urlDict[object]
  }
  else {
    return object_id
  }
}

export function addSupervisedClient(client) {
  const url = "/api/users/user_supervised_clients";
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(client),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export function deleteSupervisedClient(user_id, client_id) {
  const url_query = buildQuery({
    user_id: user_id,
    client_id: client_id,
  });
  const url = "/api/users/user_supervised_clients?" + url_query;
  return fetch(url, {
    method: "DELETE",
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export function getSupervisedModels() {
  const url = "/api/users/user-supervised-models";
  return fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export function getSupervisedSeries() {
  const url = "/api/users/user-supervised-series";
  return fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export function getClients(active) {
  const url = `/api/users/clients?active=${active}`;
  return fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export function getDistributors(active) {
  const url = `/api/users/distributors?active=${active}`;
  return fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export function getClientsDistributors(active) {
  const url = `/api/users/clients-distributors?active=${active}`;
  return fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export function getClientsSupervisedByUser(user_id, active) {
  const url_query = buildQuery({
    user_id: user_id,
    active: active,
  });
  const url = "/api/users/user_supervised_distributors?" + url_query;
  return fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export function getClientsNotSupervisedByUser(user_id, active) {
  const url_query = buildQuery({
    user_id: user_id,
    active: active,
  });
  const url = "/api/users/user_not_supervised_clients?" + url_query;
  return fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}
