import router from "@/router";
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import i18n from "@/locale/i18n";

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  state: {
    token: null,
    tokenExpiry: null,
    authenticated: false,
    username: null,
    role: {},
    user_id: null,
    type: null,
    user_consent: null,
    user_completed: null,
    client_id: null,
    backend_verify: false,
    distributor: false,
  },
  mutations: {
    setAuthentication(state, status) {
      state.authenticated = status;
    },
    setUsername(state, username) {
      state.username = username;
    },
    setClientId(state, clientId) {
      state.client_id = clientId;
    },
    setPrivileges(state, role) {
      state.role = role;
    },
    setUserId(state, id) {
      state.user_id = id;
    },
    setUserConsent(state, consent) {
      state.user_consent = consent;
    },
    setUserCompleted(state, completed) {
      state.user_completed = completed;
    },
    setType(state, type) {
      state.type = type;
    },
    setBackendVerify(state, backend_verify) {
      state.backend_verify = backend_verify;
    },
    setToken(state, token) {
      state.token = token;
    },
    setTokenExpiry(state, expiryTime) {
      state.tokenExpiry = expiryTime;
    },
    setDistributor(state, distributor) {
      state.distributor = distributor;
    },
    logout(state) {
      state.token = null;
      state.tokenExpiry = null;
      state.authenticated = false;
      state.username = null;
      state.role = {};
      state.user_id = null;
    },
  },
  actions: {
    async login({ commit }, data) {
      try {
        const url = "/api/accounts/login";
        const response = await fetch(url, {
          credentials: "same-origin",
          method: "POST",
          body: JSON.stringify(data),
        });
        if (response.status === 200) {
          const resp = await response.json();
          if (resp.csrf_token) {
            commit("setToken", resp.csrf_token);
            await store.dispatch("refreshSession");
          }
        } else {
          const errorData = await response.json();
          throw new Error(errorData.message);
        }
      } catch (error) {
        console.error("Login error:", error);
        throw error;
      }
    },
    async logout() {
      try {
        const url = "/api/accounts/logout";
        const response = await fetch(url, {
          credentials: "same-origin",
          method: "POST",
          body: JSON.stringify({
            logout: true,
          }),
        });
        if (response.status == 200) {
          return response.json();
        } else {
          throw new Error("Error logout");
        }
      } catch (error) {
        console.error("Login error:", error);
        throw error;
      }
    },
    async runLogoutMethods({ commit }) {
      const now = Date.now();
      commit("logout");
      store.dispatch("logout");
      console.log("Session expired at:", new Date(now).toLocaleTimeString());
      alert(i18n.t("sesion_expirada"));
      router.replace({ name: "Login" });
    },
    async checkTokenExpiry({ state }) {
      const now = Date.now();
      if (state.tokenExpiry && now >= state.tokenExpiry) {
        store.dispatch("runLogoutMethods");
      }
    },
    async refreshSession({ commit }) {
      try {
        const expiryTimeMinutes = 15;
        const expiryTime = Date.now() + expiryTimeMinutes * 60 * 1000;
        commit("setTokenExpiry", expiryTime);
        commit("setAuthentication", true);
      } catch (error) {
        store.dispatch("runLogoutMethods");
      }
    },
    async refreshToken({ commit, state }) {
      try {
        const response = await fetch("/api/accounts/refresh-token", {
          credentials: "same-origin",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": state.token,
          },
          body: JSON.stringify({ token: state.token }),
        });
        if (response.status === 200) {
          const resp = await response.json();
          if (resp.csrf_token) {
            commit("setToken", resp.csrf_token);
            await store.dispatch("refreshSession");
          }
        } else {
          throw new Error("Failed to refresh token");
        }
      } catch (error) {
        store.dispatch("runLogoutMethods");
      }
    },
  },
  getters: {
    isAuthenticated(state) {
      return !!state.token && Date.now() < state.tokenExpiry;
    },
  },
  modules: {},
  strict: process.env.NODE_ENV !== "production",
});

export default store;
