<template>
  <div class="fill-height">
    <Header :showSettingsSelect="false" />

    <v-content class="fill-height">
      <v-img class="img-background" z-index="0"
        src="@/assets/Fondo_Portadas_tarifa.jpg"/>
      <v-container z-index="1" fill-height fluid class="ma-0 pa-0 elevation-0" style="position: relative; z-index: 1;">
        <v-row justify="center">
          <v-col xl="4" lg="4" md="4" sm="4" cols="10" align-self="stretch">
            <Login :header="$t('login_header')" />
          </v-col>
        </v-row>
      </v-container>
    </v-content>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Login from "@/components/login/Login.vue";
import Footer from "@/components/Footer";
import Header from "@/components/Header";

export default {
  name: "home-item",
  components: {
    Login,
    Footer,
    Header,
  },
  beforeCreate() {
    document.title = "Login";
  },
};
</script>

<style scoped>
.img-background {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
</style>